const {
  GET_PACK_LIST_SUCCESS,
  GET_PACK_LIST_ERROR,
  CREATE_PACK_SUCCESS,
  CREATE_PACK_ERROR,
  GET_PACK_DETAILS_SUCCESS,
  GET_PACK_DETAILS_ERROR,
  EDIT_PACK_SUCCESS,
  EDIT_PACK_ERROR,
  TOUR_PACK_LIST_SUCCESS,
  TOUR_PACK_LIST_ERROR,
  GET_PACK_LIST,
} = require("./actionTypes");

const INIT_STATE = {
  pack: [],
  success: {},
  error: {},
  details: {},
  tourpack: [],
};

const Pack = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACK_LIST:
      return {
        ...state,
        pack: [],
      };
    case GET_PACK_LIST_SUCCESS:
      return {
        ...state,
        pack: action.payload,
      };
    case GET_PACK_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TOUR_PACK_LIST_SUCCESS:
      return {
        ...state,
        tourpack: action.payload,
      };
    case TOUR_PACK_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PACK_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
      };
    case GET_PACK_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_PACK_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case CREATE_PACK_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_PACK_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case EDIT_PACK_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Pack;
