import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

//import action
import { getChartsData, getPackList } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import TourList from "pages/Tour/tour-list";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import UserItems from "pages/items/user-items";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.togglemodal.bind(this);
    this.togglesubscribemodal.bind(this);
  }

  componentDidMount() {
    const { onGetChartsData } = this.props;
    setTimeout(() => this.setState({ subscribemodal: false }), 2000);
    onGetChartsData("yearly");
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData });
    }
  }

  render() {
    //meta title
    document.title = "Dashboard | Loadout - Admin Dashboard";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Dashboard")}
            />
            <Row>
              <Col xl="12">
                <WelcomeComp />
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <TourList />
              </Col>
              <Col xl="4">
                <UserItems />
              </Col>
              <Col xl="4">
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <div className="mb-4 h4 card-title text-start">
                            User Subs
                          </div>
                        </Row>
                        <Row>
                          <h5 className="text-center">
                            <i className="mdi mdi-av-timer"></i> Coming soon
                          </h5>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <div className="mb-4 h4 card-title text-start">
                            Billing history
                          </div>
                        </Row>
                        <Row>
                          <h5 className="text-center">
                            <i className="mdi mdi-av-timer"></i> Coming soon
                          </h5>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
});

const mapDispatchToProps = dispatch => ({
  onGetChartsData: periodType => dispatch(getChartsData(periodType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Dashboard));
