const {
  CREATE_TOUR,
  CREATE_TOUR_ERROR,
  CREATE_TOUR_SUCCESS,
  GET_TOUR_LIST,
  GET_TOUR_LIST_SUCCESS,
  GET_TOUR_LIST_ERROR,
  GET_TOUR_DETAILS_SUCCESS,
  GET_TOUR_DETAILS_ERROR,
  EDIT_TOUR_ERROR,
  EDIT_TOUR_SUCCESS,
} = require("./actionTypes");

const INIT_STATE = {
  tours: [],
  success: "",
  details: {},
  error: "",
  loading: false,
};

const tour = (state = INIT_STATE, action) => {
  //console.log(JSON.stringify(action));
  switch (action.type) {
    case CREATE_TOUR:
      return {
        ...state,
        success: action.payload,
        loading: false,
      };
    case GET_TOUR_LIST_SUCCESS:
      return {
        ...state,
        tours: action.payload,
        success: "",
        loading: true,
      };
    case GET_TOUR_LIST:
      return {
        ...state,
        tours: [],
        success: "",
        loading: true,
      };
    case GET_TOUR_LIST_ERROR:
      return {
        ...state,
        tours: [],
        error: action.payload,
      };
    case CREATE_TOUR_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: true,
      };
    case CREATE_TOUR_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case EDIT_TOUR_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: true,
      };
    case EDIT_TOUR_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_TOUR_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        success: "",
      };
    case GET_TOUR_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default tour;
