export const CREATE_PACK = "CREATE_PACK";
export const CREATE_PACK_SUCCESS = "CREATE_PACK_SUCCESS";
export const CREATE_PACK_ERROR = "CREATE_PACK_ERROR";

export const GET_PACK_LIST = "GET_PACK_LIST";
export const GET_PACK_LIST_SUCCESS = "GET_PACK_LIST_SUCCESS";
export const GET_PACK_LIST_ERROR = "GET_PACK_LIST_ERROR";

export const TOUR_PACK_LIST = "TOUR_PACK_LIST";
export const TOUR_PACK_LIST_SUCCESS = "TOUR_PACK_LIST_SUCCESS";
export const TOUR_PACK_LIST_ERROR = "TOUR_PACK_LIST_ERROR";

export const GET_PACK_DETAILS = "GET_PACK_DETAILS";
export const GET_PACK_DETAILS_SUCCESS = "GET_PACK_DETAILS_SUCCESS";
export const GET_PACK_DETAILS_ERROR = "GET_PACK_DETAILS_ERROR";

export const EDIT_PACK = "EDIT_PACK";
export const EDIT_PACK_SUCCESS = "EDIT_PACK_SUCCESS";
export const EDIT_PACK_ERROR = "EDIT_PACK_ERROR";
