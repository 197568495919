import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Alert,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getTours,
  getToursSuccess,
  getToursError,
} from "../../store/tour/actions";

import "./datatables.scss";

class TourList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
    };
    this.props.getTours();
    this.formatDate.bind(this);
  }

  formatDate(
    value,
    formatting = { month: "short", day: "numeric", year: "numeric" }
  ) {
    if (!value) return value;
    const date = new Date(value);
    const day = date.toLocaleDateString(undefined, { day: "2-digit" });
    const month = date.toLocaleDateString(undefined, { month: "short" });
    const year = date.toLocaleDateString(undefined, { year: "numeric" });
    const hours = `0${date.getHours()}`.slice(-2);
    const min = `0${date.getMinutes()}`.slice(-2);
    const ampm = hours >= 12 ? "pm" : "am";
    const concateDate = `${day} ${month} ${year} ${hours}:${min} ${ampm}`;
    return concateDate;
  }

  render() {
    var tourslist = this.props.tours;
    tourslist = tourslist.reverse();
    const columns = [
      {
        dataField: "det.nm",
        text: "Name",
        sort: true,
      },
      {
        dataField: "det.snm",
        text: "Sub Name",
        sort: true,
      },
      // {
      //   text: "Packs",
      //   sort: true,
      //   formatter: (value, row) => <>{row.pck ? row.pck.length : 0}</>,
      // },
      // {
      //   dataField: "sub.crtd",
      //   text: "Create At",
      //   sort: true,
      //   formatter: (value, row) => (
      //     <>{row.sub ? this.formatDate(row.sub.crtd * 1000) : ""}</>
      //   ),
      // },
      {
        text: "Status",
        sort: false,
        formatter: (value, row) => (
          <>
            {row.det && row.det.st ? (
              <span className="text-success">Active</span>
            ) : (
              <span className="text-danger">In Active</span>
            )}
          </>
        ),
      },
      {
        text: "Action",
        formatter: (value, row) => (
          <>
            {/* <Link to={"edit-tour/" + row.id} className="btn btn-info btn-sm">
              <i className="bx bx-pencil" />
            </Link>{" "} */}
            <Link to={"view-tour/" + row.id} className="btn btn-info btn-sm">
              <i className="fa fa-eye" />
            </Link>
          </>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: "sub.crtd",
        formatter: (value, row) => <>{row.sub ? row.sub.crtd : 0}</>,
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      // totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    const onRowClick = {
      onClick: (e, row, rowIndex) => {
        this.props.history.push("/view-tour/" + row.id);
      },
    };

    //meta title
    document.title = "Data Tables | Skote - React Admin & Dashboard Template";
    //console.log(this.props.success.tour);
    return (
      <React.Fragment>
        {/* <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />

            <Row>
              <Col className="col-12"> */}
        <Card>
          {/* <CardHeader className="text-end">
            <Link to="/new-tour" className="btn btn-info">
              <i className="bx bx-plus" /> Add New Tour
            </Link>
          </CardHeader> */}
          <CardBody>
            <Row>
              <Col xl="6">
                <div className="mb-4 h4 card-title text-start">Tours List</div>
              </Col>
              <Col xl="6">
                <div className="mb-4 text-end">
                  <Link to="/new-tour" className="btn btn-sm btn-info">
                    <i className="bx bx-plus" /> Add New Tour
                  </Link>
                </div>
              </Col>
            </Row>

            {/* {this.props.error ? (
                      <Alert color="danger">{this.props.error}</Alert>
                    ) : null}
                    {this.props.success.tour &&
                    this.props.success.tour != "" ? (
                      <Alert color="success">{this.props.success.tour}</Alert>
                    ) : null} */}
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={columns}
              data={tourslist}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={tourslist}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              rowEvents={onRowClick}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
        {/* </Col>
            </Row>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { error, success, tours } = state.Tour;
  //console.log(state.Tour);
  return { error, success, tours };
};

export default withRouter(
  connect(mapStateToProps, { getTours, getToursSuccess, getToursError })(
    TourList
  )
);
