import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import { getTourDetails, tourPackList } from "store/actions";
import PackList from "pages/packs/pack-list";
import TourPackList from "pages/packs/tour-pack-list";
const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj;
  }
};

const getUserId = () => {
  if (localStorage.getItem("Uid")) {
    const obj = localStorage.getItem("Uid");
    return obj;
  }
};
import { getStripeProduct } from "./../../store/products/actions";

class ViewTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "admin@mailinator.com",
      profile: "",
      name: "Admin",
      uid: "",
    };
  }

  componentDidMount() {
    this.props.getStripeProduct();
    const userData = getUserName();
    //console.log(userData);
    if (userData) {
      this.setState({
        name: userData.pub.fn + " " + userData.pub.ln,
        profile: userData.pub.pURL,
        email: userData.pvt.eml,
      });
    }
    const usrId = getUserId();
    if (usrId) {
      this.setState({ uid: usrId });
    }
    this.props.getTourDetails(this.props.match.params.id);
    this.props.tourPackList(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        //  console.log(userData);
        this.setState({
          name: userData.pub.fn + " " + userData.pub.ln,
          profile: userData.pub.pURL,
          email: userData.pvt.eml,
        });
      }
    }
  }

  render() {
    const details = this.props.details;
    const product = this.props.product;
    //console.log(product);
    var productDetails = "";
    for (let p of product) {
      if (details.stp && details.stp.status == "active") {
        if (details.stp.plan.product == p.id) {
          productDetails = p;
        }
      }
    }
    //console.log(productDetails);
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Tour" breadcrumbItem="Tour Details" />
            {this.props.success && this.props.success.tour ? (
              <Alert color="success">{this.props.success.tour}</Alert>
            ) : null}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="4">
                        <div className="d-flex">
                          {details && details.det && details.det.pURL != "" ? (
                            <div className="me-3">
                              <img
                                src={details.det.pURL}
                                alt=""
                                style={{ width: "150px" }}
                              />
                            </div>
                          ) : null}
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <p className="mb-2">Tourd Details - </p>
                              {details && details.det ? (
                                <h5 className="mb-1">{details.det.nm}</h5>
                              ) : null}
                              {details && details.det ? (
                                <p className="mb-0">{details.det.snm}</p>
                              ) : null}
                              {details && details.det ? (
                                details.det.st ? (
                                  <p className="mb-0 text-success">Active</p>
                                ) : (
                                  <p className="mb-0 text-danger">In-Active</p>
                                )
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg="4" className="align-self-center">
                        <div className="text-lg-center mt-4 mt-lg-0">
                          <Row>
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Total Packs
                              </p>
                              <h5 className="mb-0">
                                {this.props.tourpack.length}
                              </h5>
                            </div>
                          </Row>
                        </div>
                      </Col>

                      <Col lg="4" className="d-none d-lg-block">
                        <div className="clearfix mt-4 mt-lg-0 float-end">
                          {details && details.det ? (
                            <>
                              <Link
                                to="/"
                                className="btn btn-secondary btn-sm "
                              >
                                <i className="bx bx-undo" />
                              </Link>{" "}
                              <Link
                                to={"/edit-tour/" + this.props.match.params.id}
                                className="btn btn-info btn-sm"
                              >
                                <i className="bx bx-pencil" />
                              </Link>
                            </>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                {productDetails ? (
                  <TourPackList
                    id={this.props.match.params.id}
                    pricing={productDetails}
                  />
                ) : (
                  <TourPackList id={this.props.match.params.id} />
                )}
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4 h4 card-title text-start">
                        Users List
                      </div>
                    </Row>
                    <Row>
                      <h5 className="text-center">
                        <i className="mdi mdi-av-timer"></i> Coming soon
                      </h5>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <div className="mb-4 h4 card-title text-start">
                            Billing history
                          </div>
                        </Row>
                        <Row>
                          {details &&
                          details.stp &&
                          details.stp.status == "active" &&
                          productDetails != "" ? (
                            <>
                              <h3>${productDetails.metadata.price}</h3>
                              <p>{productDetails.description}</p>
                              <p>
                                {productDetails.metadata.packs} packs Allowed
                              </p>
                              <p>
                                {productDetails.metadata.items} items per pack
                                Allowed
                              </p>
                            </>
                          ) : (
                            <h5 className="text-center">
                              <i className="mdi mdi-av-timer"></i> Coming soon
                            </h5>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {details.usr && this.state.uid ? (
                  this.state.uid in details.usr &&
                  details.usr[this.state.uid] == 0 ? (
                    <Row>
                      <Col xl="12">
                        <Card>
                          <CardBody>
                            <Row>
                              <div className="mb-4 h4 card-title text-start">
                                Subscription status
                              </div>
                            </Row>
                            <Row>
                              <h5>
                                Status :{" "}
                                {details &&
                                details.sub &&
                                details.sub.nxt > moment(new Date()).unix() ? (
                                  <span className="text-success">Active</span>
                                ) : (
                                  <span className="text-danger">Expired</span>
                                )}
                              </h5>
                              <p>
                                Type :{" "}
                                {productDetails
                                  ? productDetails.name
                                  : details &&
                                    details.sub &&
                                    details.sub.sty == 0
                                  ? "Trial"
                                  : details &&
                                    details.sub &&
                                    details.sub.sty == 1
                                  ? "Small"
                                  : details &&
                                    details.sub &&
                                    details.sub.sty == 2
                                  ? "Medium"
                                  : details &&
                                    details.sub &&
                                    details.sub.sty == 3
                                  ? "Large"
                                  : "N/A"}{" "}
                              </p>
                              <p>
                                Subscribed On :{" "}
                                {moment
                                  .unix(
                                    details && details.sub && details.sub.crtd
                                  )
                                  .format("DD MMMM YYYY")}
                              </p>
                              <p>
                                Renewal Date :{" "}
                                {moment
                                  .unix(
                                    details && details.sub && details.sub.nxt
                                  )
                                  .format("DD MMMM YYYY")}
                              </p>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  ) : null
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ViewTour.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  details: PropTypes.object,
  product: PropTypes.object,
};

const mapStateToProps = state => {
  const { error, success, details } = state.Tour;
  const { subscribe, product } = state.StripeProduct;
  const { tourpack } = state.Pack;
  return { error, success, details, tourpack, subscribe, product };
};

export default withRouter(
  connect(mapStateToProps, {
    getTourDetails,
    getStripeProduct,
    tourPackList,
  })(ViewTour)
);
