import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  SOCIAL_USER_SUCCESSFUL,
} from "./actionTypes"

export const registerUser = (user , history) => {
  return {
    type: REGISTER_USER,
    payload: { user , history},
  }
}

export const registerUserDetails  = (user , history) => {
  return {
    type: REGISTER_USER_DETAILS,
    payload: { user , history},
  }
}

export const registerUserSuccessful = message => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: message,
  }
}

export const socialUserSuccessful = message => {
  return {
    type: SOCIAL_USER_SUCCESSFUL,
    payload: message,
  }
}

export const registerUserFailed = error => {
  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  }
}

