import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// Get Tours
import { getTours } from "./../../store/tour/actions";
import {
  createItem,
  itemDetails,
  updateItem,
} from "./../../store/item/actions";
import { getPackList } from "../../store/pack/actions";

class EditItem extends Component {
  constructor(props) {
    super(props);
    this.state = { tour: "", isLoading: false };
  }

  componentDidMount() {
    this.props.itemDetails(this.props.match.params.id);
    this.getItemDetails();
  }

  getItemDetails() {
    if (
      this.props &&
      this.props.details &&
      this.props.details &&
      this.props.details.details.det
    ) {
      const { nm, col, dX, dW, dY, dZ } = this.props.details.details.det;
      this.setState({
        name: nm,
        color: col,
        dX: dX,
        dY: dY,
        dZ: dZ,
        dW: dW,
        itemid: this.props.match.params.id,
      });
    }
  }

  change = event => {
    this.setState({ tour: event.target.value });
  };

  render() {
    const { details } = this.props;
    var packlist = this.props.pack;
    //meta title
    document.title = "New Item | Loadout Admin Dashboard";
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Item" breadcrumbItem="Edit Item" />
              <Row>
                <Col lg="12" sm="12">
                  <Card>
                    <CardBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          id:
                            (details &&
                              details.details &&
                              details.details.det &&
                              details.details.det.id) ||
                            "",
                          name:
                            (details &&
                              details.details &&
                              details.details.det &&
                              details.details.det.nm) ||
                            "",
                          color:
                            (details &&
                              details.details &&
                              details.details.det &&
                              details.details.det.col) ||
                            "",
                          dW:
                            (details &&
                              details.details &&
                              details.details.det &&
                              details.details.det.dW) ||
                            "",
                          dX:
                            (details &&
                              details.details &&
                              details.details.det &&
                              details.details.det.dX) ||
                            "",
                          dY:
                            (details &&
                              details.details &&
                              details.details.det &&
                              details.details.det.dY) ||
                            "",
                          dZ:
                            (details &&
                              details.details &&
                              details.details.det &&
                              details.details.det.dZ) ||
                            "",
                          photo:
                            (details &&
                              details.details &&
                              details.details.det &&
                              details.details.det.photo) ||
                            "",
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required("Please Enter item Name"),
                          color: Yup.string().required(
                            "Please Enter Color Code"
                          ),
                          dW: Yup.string().required("Please Enter value"),
                          dX: Yup.string().required("Please Enter value"),
                          dY: Yup.string().required("Please Enter value"),
                          dZ: Yup.string().required("Please Enter value"),
                        })}
                        onSubmit={values => {
                          //console.log(values, "========values");
                          values.id = this.props.match.params.id;
                          this.setState({ isLoading: true });
                          this.props.updateItem(values, this.props.history);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form>
                            <div>
                              <p className="card-title-desc">
                                Fill all information below
                              </p>
                              <Row>
                                <Col lg="12" sm="12">
                                  <div className="mb-3">
                                    <Label>Name</Label>
                                    <Field
                                      name="name"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.name && touched.name
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="name"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="12" sm="12">
                                  <div className="mb-3">
                                    <Label>Color Code</Label>
                                    <Field
                                      name="color"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.color && touched.color
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="color"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3" sm="3">
                                  <div className="mb-3">
                                    <Label>dW Value</Label>
                                    <Field
                                      name="dW"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.dW && touched.dW
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="dW"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3" sm="3">
                                  <div className="mb-3">
                                    <Label>dX Value</Label>
                                    <Field
                                      name="dX"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.dX && touched.dX
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="dX"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3" sm="3">
                                  <div className="mb-3">
                                    <Label>dY Value</Label>
                                    <Field
                                      name="dY"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.dY && touched.dY
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="dY"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3" sm="3">
                                  <div className="mb-3">
                                    <Label>dZ Value</Label>
                                    <Field
                                      name="dZ"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.dZ && touched.dZ
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="dZ"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="12" sm="12">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="photo"
                                      md="2"
                                      className="col-form-label"
                                    >
                                      Upload File
                                    </Label>
                                    <Field
                                      name="photo"
                                      type="file"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name="photo"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-start">
                                    <Button
                                      className="btn btn-dark"
                                      onClick={this.props.history.goBack}
                                    >
                                      Back
                                    </Button>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      className="btn btn-info"
                                      type="submit"
                                    >
                                      Create Item
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                              {/* <div className="mb-3">
                                  <button
                                    className="btn btn-info mt-3"
                                    type="submit"
                                  >
                                    Create Item
                                  </button>
                                </div> */}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

EditItem.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  tours: PropTypes.object,
  createPack: PropTypes.func,
  pack: PropTypes.object,
};

// const mapStateToProps = state => {
//     console.log(state);
//     const { error, success, tours } = state.Tour;
//     const { details } = state.Item;
//     console.log(details)

//     const {  pack } = state.Pack;
//     return { error, success, tours , pack , details };
// };

const mapStateToProps = state => ({
  error: state.Tour.error,
  success: state.Tour.success,
  tours: state.Tour.tours,
  pack: state.Pack,
  details: state.Item,
});

export default withRouter(
  connect(mapStateToProps, { getTours, createItem, itemDetails, updateItem })(
    EditItem
  )
);
