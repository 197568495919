import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import lightlogo from "../../../assets/images/loadoutlogo.png";
import {
  getStripeProduct,
  createSubscription,
} from "./../../../store/products/actions";
import {
  createTour,
  createTourSuccess,
  createTourError,
} from "../../../store/tour/actions";
import { Spinner } from "reactstrap";

class CheckoutForm extends Component {
  //  = ({pricing , name , email}) => {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  publishableKey =
    "pk_test_51J61QmLUvUWnsm1rDVAYpYjnXxITBQuecPRj0vBNQL5mg5qBQuob2njCJoMqQk3BVZULXsuSRoNhFZbwCpI4X4KV00uPb66Zx3";

  onToken = token => {
    //console.log(token);
    const body = {
      token: token.id,
      price_id: this.props.pricing.metadata.price_key,
      name: this.props.name,
      email: this.props.email,
      tour: this.props.tourData,
      productId: this.props.productId,
      package_name: this.props.pricing.name,
      price: this.props.pricing.metadata.price,
    };
    //console.log(body);
    this.setState({ isLoading: true });
    this.props.createSubscription(body, this.props.history);
    if (this.props.error != "") {
      this.setState({ isLoading: false });
      this.props.onSubscribe(true);
    } else {
      this.props.onSubscribe(true);
    }
  };

  spinner() {
    return <Spinner />;
  }

  render() {
    //console.log(this.props);
    return (
      <>
        {/* {this.state.isLoading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : ( */}
        <>
          <StripeCheckout
            label="Subscribe & Create Tour"
            name={this.props.name}
            description={this.props.pricing.name + " Plan"}
            panelLabel="Pay Now"
            amount={this.props.pricing.metadata.price * 100}
            token={this.onToken}
            stripeKey="pk_test_51J61QmLUvUWnsm1rDVAYpYjnXxITBQuecPRj0vBNQL5mg5qBQuob2njCJoMqQk3BVZULXsuSRoNhFZbwCpI4X4KV00uPb66Zx3"
            image={lightlogo}
            billingAddress={false}
            email={this.props.email}
          />
        </>
        {/* )} */}
      </>
    );
  }
}

const mapStateToProps = state => {
  //console.log("state===>", state);
  const { error } = state.Tour;
  const { subscribe } = state.StripeProduct;
  return { subscribe, error };
};

export default withRouter(
  connect(mapStateToProps, {
    createSubscription,
    createTour,
    createTourError,
    createTourSuccess,
  })(CheckoutForm)
);
