import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import { getTourDetails, tourPackList, getPackDetails } from "store/actions";
import PackList from "pages/packs/pack-list";
import TourPackList from "pages/packs/tour-pack-list";
const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj;
  }
};
import { getStripeProduct } from "./../../store/products/actions";
import PackItemList from "pages/items/pack-item-list";

class ViewPack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "admin@mailinator.com",
      profile: "",
      name: "Admin",
    };
  }

  componentDidMount() {
    this.props.getStripeProduct();
    const userData = getUserName();
    //console.log(userData);
    if (userData) {
      this.setState({
        name: userData.pub.fn + " " + userData.pub.ln,
        profile: userData.pub.pURL,
        email: userData.pvt.eml,
      });
    }
    this.props.getPackDetails(this.props.match.params.id);
    this.props.tourPackList(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        //console.log(userData);
        this.setState({
          name: userData.pub.fn + " " + userData.pub.ln,
          profile: userData.pub.pURL,
          email: userData.pvt.eml,
        });
      }
    }
  }

  render() {
    const details = this.props.details;
    //console.log(details);
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Tour" breadcrumbItem="Pack Details" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="4">
                        <div className="d-flex">
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <p className="mb-2">Pack Details - </p>
                              {details && details.det ? (
                                <h5 className="mb-1">{details.det.nm}</h5>
                              ) : null}
                              {details && details.det ? (
                                <p className="mb-0">{details.det.cat}</p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg="4" className="align-self-center">
                        <div className="text-lg-center mt-4 mt-lg-0">
                          <Row>
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Total Pack
                              </p>
                              <h5 className="mb-0">
                                {this.props.tourpack.length}
                              </h5>
                            </div>
                          </Row>
                        </div>
                      </Col>

                      <Col lg="4" className="d-none d-lg-block">
                        <div className="clearfix mt-4 mt-lg-0 float-end">
                          {details && details.det ? (
                            <>
                              <Link
                                to={"/view-tour/" + details.det.tUID}
                                className="btn btn-secondary btn-sm "
                              >
                                <i className="bx bx-undo" />
                              </Link>{" "}
                              <Link
                                to={"/edit-pack/" + this.props.match.params.id}
                                className="btn btn-info btn-sm"
                              >
                                <i className="bx bx-pencil" />
                              </Link>
                            </>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <PackItemList />
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4 h4 card-title text-start">
                        Users List
                      </div>
                    </Row>
                    <Row>
                      <h5 className="text-center">
                        <i className="mdi mdi-av-timer"></i> Coming soon
                      </h5>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4 h4 card-title text-start">
                        Users List
                      </div>
                    </Row>
                    <Row>
                      <h5 className="text-center">
                        <i className="mdi mdi-av-timer"></i> Coming soon
                      </h5>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ViewPack.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  details: PropTypes.object,
  product: PropTypes.object,
};

const mapStateToProps = state => {
  const { error, success } = state.Pack;
  const { subscribe, product } = state.StripeProduct;
  const { tourpack, details } = state.Pack;
  return { error, success, details, tourpack, subscribe, product };
};

export default withRouter(
  connect(mapStateToProps, {
    getTourDetails,
    getStripeProduct,
    tourPackList,
    getPackDetails,
  })(ViewPack)
);
