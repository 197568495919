import PropTypes from "prop-types";
import React, { Component, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/profile-img.png";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj;
  }
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.inputFile = React.createRef();

    this.state = {
      idx: 1,
      menu: false,
      email: "admin@mailinator.com",
      profile: "",
      name: "Admin",
      image: "",
      type: "",
      file: "",
      phone: "",
    };
  }

  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      this.setState({
        fn: userData.pub.fn,
        ln: userData.pub.ln,
        profile: userData.pub.pURL,
        email: userData.pvt.eml,
        phone: userData.pvt.phn,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({
          fn: userData.pub.fn,
          ln: userData.pub.ln,
          profile: userData.pub.pURL,
          email: userData.pvt.eml,
          phone: userData.pvt.phn,
        });
      }
    }
  }

  uploadImage = () => {
    //console.log("Hellooww world");
    this.inputFile.current.click();
  };

  handleChange = event => {
    const file = event.target.files[0];
    this.setState({ file: file });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
        this.setState({ image: event.target.result.split(",")[1] });
        var type = event.target.result.split("/")[1];
        this.setState({ type: type.split(";")[0] });
        this.setState({ profile: event.target.result });
      };
      reader.onerror = err => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
    // if (!fileObject) return;
    // renderImage(fileObject);
  };

  render() {
    //console.log(this.state);
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Skote" breadcrumbItem="Profile" />
            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="profilepic me-3">
                        <img
                          src={
                            this.state.profile != ""
                              ? this.state.profile
                              : avatar
                          }
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail profilepic__image"
                        />
                        <div
                          className="profilepic__content"
                          onClick={this.uploadImage}
                        >
                          <span className="profilepic__icon">
                            <i className="fas fa-camera"></i>
                          </span>
                          <input
                            type="file"
                            id="file"
                            onChange={this.handleChange}
                            ref={this.inputFile}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>
                            {this.state && this.state.fn ? this.state.fn : ""}{" "}
                            {this.state && this.state.ln ? this.state.ln : ""}
                          </h5>
                          <p className="mb-1">{this.state.email}</p>
                          <p className="mb-0">
                            Phone number: {this.state.phone}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change User Details</h4>

            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    firstname: (this.state && this.state.fn) || "",
                    lastname: (this.state && this.state.ln) || "",
                    email: (this.state && this.state.email) || "",
                    phone: (this.state && this.state.phone) || "",
                    idx: (this.state && this.state.idx) || "",
                    file: (this.state && this.state.file) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    firstname: Yup.string().required(
                      "Please Enter Your First Name"
                    ),
                    lastname: Yup.string().required(
                      "Please Enter Your Last name"
                    ),
                    email: Yup.string().required("Please Enter Your Email"),
                    phone: Yup.string().required(
                      "Please Enter Your Phone Number"
                    ),
                  })}
                  onSubmit={values => {
                    console.log("profile values", values);
                    this.props.editProfile(values);
                  }}
                >
                  {({ errors, status, touched }) => (
                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label for="firstname" className="form-label">
                          First Name
                        </Label>
                        <Field
                          name="firstname"
                          type="text"
                          className={
                            "form-control" +
                            (errors.firstname && touched.firstname
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="firstname"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="lastname" className="form-label">
                          Last Name
                        </Label>
                        <Field
                          name="lastname"
                          type="text"
                          className={
                            "form-control" +
                            (errors.lastname && touched.lastname
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="email" className="form-label">
                          Email
                        </Label>
                        <Field
                          name="email"
                          type="text"
                          readOnly="readonly"
                          className={
                            "form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="phone" className="form-label">
                          Phone Number
                        </Label>
                        <Field
                          name="phone"
                          type="text"
                          className={
                            "form-control" +
                            (errors.phone && touched.phone ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <Field
                        name="idx"
                        type="hidden"
                        className={
                          "form-control" +
                          (errors.idx && touched.idx ? " is-invalid" : "")
                        }
                      />
                      <div className="text-center mt-4">
                        <Button type="submit" color="danger">
                          Update Profile
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};

const mapStateToProps = state => {
  //console.log("State profile", state.Profile);
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(UserProfile)
);
