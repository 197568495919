import { call, put, takeEvery } from "redux-saga/effects";
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import {
  createItemError,
  createItemSuccess,
  getItemListError,
  getItemListSuccess,
  itemDetailsError,
  itemDetailsSuccess,
  updateItem,
  updateItemError,
  updateItemSuccess,
} from "./actions";
import {
  CREATE_ITEM,
  GET_ITEM_LIST,
  ITEM_DETAILS,
  UPDATE_ITEM,
} from "./actionTypes";

const fireBaseBackend = getFirebaseBackend();

function* getItemList() {
  try {
    const responce = yield call(fireBaseBackend.getItem);
    if (responce) {
      yield put(getItemListSuccess(responce));
    } else {
      yield put(getItemListError("Something went wrong!!"));
    }
  } catch (error) {
    yield put(getItemListError(error));
  }
}

function* getItemDetails({ payload: data }) {
  try {
    const responce = yield call(fireBaseBackend.getItemDetails, data);
    if (responce) {
      yield put(itemDetailsSuccess(responce));
    } else {
      yield put(itemDetailsError("Something went wrong!!"));
    }
  } catch (error) {
    yield put(itemDetailsError(error));
  }
}

function* createItem({ payload: { item, history } }) {
  try {
    const responce = yield call(fireBaseBackend.createItem, item);
    if (responce) {
      yield put(createItemSuccess("Item create successfully!!"));
      history.push("/dashboard");
    } else {
      yield put(createItemError(responce));
    }
  } catch (error) {
    yield put(createItemError(error));
  }
}

function* editItem({ payload: { item, history } }) {
  try {
    const responce = yield call(fireBaseBackend.editItem, item);
    if (responce) {
      yield put(updateItemSuccess("Item update successfully!!"));
      history.push("/dashboard");
    } else {
      yield put(updateItemError(responce));
    }
  } catch (error) {
    yield put(updateItemError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_ITEM_LIST, getItemList);
  yield takeEvery(CREATE_ITEM, createItem);
  yield takeEvery(ITEM_DETAILS, getItemDetails);
  yield takeEvery(UPDATE_ITEM, editItem);
}

export default itemSaga;
