import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  OAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAnsNNRJSrLMLoMae3D052HJIX9NtspTC0",
  authDomain: "loadout-db.firebaseapp.com",
  databaseURL: "https://loadout-db.firebaseio.com",
  projectId: "loadout-db",
  storageBucket: "loadout-db.appspot.com",
  messagingSenderId: "958377178006",
  appId: "1:958377178006:web:3a8103ee33a5ccc270a3b7",
  measurementId: "G-XB5DJ0N4JJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    var users = "";
    const docs = await getDocs(
      query(collection(db, "users"), where("uid", "==", user.uid))
    );
    if (docs.docs.length === 0) {
      users = await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
    return users;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

// Register with email and password

const registerWithEmailPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
    // const query = query(collection(db , 'users') , where("uid" , '==' , user.uid));
    //console.log(user)
  } catch (err) {
    //console.log(err)
    if (err.message.includes("email-already-in-use")) {
      alert("Email Id Already exist");
    } else {
      alert(err.message);
    }
  }
};

// Login with email and password

const loginInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const user = res.user;
    //console.log(user);
  } catch (error) {
    if (err.message.includes("user-not-found")) {
      alert("User Not Found!!");
    } else {
      alert(err.message);
    }
  }
};

// Password reset link on email

const sendPasswordReset = async email => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!!");
  } catch (error) {
    alert(error.message);
  }
};

// apple signin

const signInApple = async () => {
  try {
    const res = await signInWithPopup(auth, appleProvider);
    const credential = OAuthProvider.credentialFromResult(res);
    const accessToken = credential.accessToken;
    const idToken = credential.idToken;

    //console.log(credential);
    return credential;
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  registerWithEmailPassword,
  loginInWithEmailAndPassword,
  sendPasswordReset,
  signInApple,
};
