import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  FormGroup,
  Label,
  CardBody,
  CardText,
  CardTitle,
  CardFooter,
  CardSubtitle,
  Button,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";

import classnames from "classnames";

import axios from "axios";

// import stripe
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import CheckoutForm from "../NewTour/CheckoutForm";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Images
import img1 from "../../../assets/images/product/img-1.png";
import img7 from "../../../assets/images/product/img-7.png";
import {
  createTour,
  editTour,
  createTourSuccess,
  createTourError,
  getTourDetails,
} from "../../../store/tour/actions";
import {
  getStripeProduct,
  createSubscription,
} from "./../../../store/products/actions";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj;
  }
};

class EditTour extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      name: "",
      email: "",
      activeTab: "",
      step: "1",
      productList: [],
      selectedGroup: null,
      tourid: "",
      status: false,
      tourData: {},
      imagePreview: "",
      photo: "",
      title: "",
      nm: "",
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }

  componentDidMount() {
    this.getProducts();
    const userData = getUserName();
    if (userData) {
      this.setState({
        name: userData.pub.fn + " " + userData.pub.ln,
        email: userData.pvt.eml,
      });
    }
    this.getDetails();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.details && nextProps.details.det) {
      const { nm, snm, st, pURL } = nextProps.details.det;
      this.setState({
        title: nm,
        subName: snm,
        status: st,
        tourid: this.props.match.params.id,
        imagePreview: pURL,
      });
    }
  }

  getDetails() {
    this.props.getTourDetails(this.props.match.params.id);
  }

  selectPackage = (product, index) => {
    //console.log(product);
    this.setState({ selectedPackage: product.id });
    this.setState({ productNum: index + 1 });
    this.setState({ selectedProduct: product });
  };

  getProducts = async () => {
    this.props.getStripeProduct();
  };

  toggleTab(tab) {
    if (this.state.step !== tab) {
      this.setState({
        step: tab,
      });
    }
  }

  makePayment = () => {
    if (this.props.details && this.props.details.stp) {
      const orderData = {
        priceId: this.state.selectedProduct.metadata.price_key,
        type: "test",
        name: this.state.name,
        customer_email: this.state.email,
        client_reference_id: "",
        customer: this.props.details.stp.customer,
        tour: this.state.tourData,
        subscription_id: this.props.details.stp.id,
        customer_id: this.props.details.stp.customer,
        productId: this.state.productNum,
        package_name: this.state.selectedProduct.name,
        price: this.state.selectedProduct.metadata.price,
      };
      //console.log("orderData1", orderData);
      this.props.createSubscription(orderData, this.props.history);
    } else {
      const orderData = {
        priceId: this.state.selectedProduct.metadata.price_key,
        type: "test",
        name: this.state.name,
        customer_email: this.state.email,
        client_reference_id: "",
        customer: "",
        tour: this.state.tourData,
        productId: this.state.productNum,
        package_name: this.state.selectedProduct.name,
        price: this.state.selectedProduct.metadata.price,
      };
      //console.log("orderData2", orderData);
      this.props.createSubscription(orderData, this.props.history);
    }
  };

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup });
  };

  toggleTab(tab) {
    if (this.state.step !== tab) {
      this.setState({
        step: tab,
      });
    }
  }

  onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      this.setState({ imagePreview: undefined });
      return;
    }
    this.setState({ photo: e.target.files[0] });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        resolve(e.target.result);
        this.setState({ image: e.target.result.split(",")[1] });
        var type = e.target.result.split("/")[1];
        this.setState({ type: type.split(";")[0] });
        this.setState({ imagePreview: e.target.result });
      };
      reader.onerror = err => {
        reject(err);
      };
      reader.readAsDataURL(e.target.files[0]);
    });
  };

  render() {
    const details = this.props.details;
    const product = this.props.product;
    //console.log(product);
    var productDetails = "";
    for (let p of product) {
      if (details.stp && details.stp.status == "active") {
        if (details.stp.plan.product == p.id) {
          productDetails = p;
        }
      }
    }

    var newProductData = [];

    for (let p of product) {
      if (productDetails != "") {
        // if (productDetails.metadata.price <= p.metadata.price) {
        newProductData.push(p);
        // }
      } else {
        newProductData.push(p);
      }
    }

    document.title = "New Tour | Loadout - Admin Panel";

    const { selectedGroup } = this.state;

    return (
      <React.Fragment>
        {/* <Form> */}
        {this.state.isLoading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            {/* {this.props.error && this.props.error ? (
              <Alert color="danger">{this.props.error}</Alert>
            ) : null}
            {this.props.success && this.props.success ? (
              <Alert color="success">{this.props.success}</Alert>
            ) : null} */}
            <Formik
              enableReinitialize={true}
              initialValues={{
                id: (this.state && this.state.tourid) || "",
                title: (details && details.det && details.det.nm) || "",
                subName: (details && details.det && details.det.snm) || "",
                photo: (details && details.det && details.det.photo) || "",
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string().required("Please Enter Tour Title"),
                subName: Yup.string().required("Please Enter Sub Name"),
              })}
              onSubmit={values => {
                if (
                  // this.props.details.det.st &&
                  // this.props.details.sub.nxt > moment().unix()
                  this.state.isSubmit
                ) {
                  values.productid = this.props.details.det.sty;
                  this.setState({ isLoading: true });
                  this.props.editTour(values, this.props.history);
                } else {
                  this.setState({ tourData: values });
                  this.toggleTab("2");
                }
              }}
            >
              {({ errors, status, touched }) => (
                <Form>
                  <div className="page-content">
                    <Container fluid>
                      {/* Render Breadcrumb */}
                      <Breadcrumbs
                        title="Ecommerce"
                        breadcrumbItem="Edit Tour"
                      />
                      <div className="checkout-tabs">
                        <TabContent activeTab={this.state.step}>
                          <TabPane tabId="1">
                            <Row>
                              <Col lg="12" sm="12">
                                <Card>
                                  <CardBody>
                                    <div>
                                      <CardTitle className="h4">
                                        Edit Tour
                                      </CardTitle>
                                      <p className="card-title-desc">
                                        Fill all information below
                                      </p>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="title"
                                          md="2"
                                          className="col-form-label"
                                        >
                                          Name
                                        </Label>
                                        <Field
                                          name="title"
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.title && touched.title
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="title"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="sub-name"
                                          md="2"
                                          className="col-form-label"
                                        >
                                          Sub Name
                                        </Label>
                                        <Field
                                          name="subName"
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.subName && touched.subName
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="subName"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="photo"
                                          md="2"
                                          className="col-form-label"
                                        >
                                          Upload photo
                                        </Label>
                                        <Field
                                          name="photo"
                                          type="file"
                                          onChange={this.onSelectFile}
                                          className="form-control"
                                        />
                                        <ErrorMessage
                                          name="photo"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <img
                                          style={{ width: "20%" }}
                                          src={this.state.imagePreview}
                                        />
                                      </div>
                                      <Row>
                                        <Col>
                                          <div className="text-start">
                                            <Link
                                              to={
                                                "/view-tour/" +
                                                this.props.match.params.id
                                              }
                                              className="btn btn-dark"
                                            >
                                              Back
                                            </Link>
                                          </div>
                                        </Col>
                                        <Col>
                                          <div className="text-end">
                                            {/* {this.props.details &&
                                            this.props.details.det &&
                                            this.props.details.sub.nxt >
                                              moment(new Date()).unix() ? ( */}
                                            <button
                                              className="btn btn-info"
                                              onClick={() =>
                                                this.setState({
                                                  isSubmit: true,
                                                })
                                              }
                                            >
                                              Save Change
                                            </button>{" "}
                                            {/* // ) : ( */}
                                            <button className="btn btn-info">
                                              <i className="bx bx-money me-1" />
                                              Select Subscription
                                            </button>
                                            {/* )} */}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Card>
                              <CardBody>
                                <div>
                                  <CardTitle className="h4">
                                    Select Subscription Package
                                  </CardTitle>
                                  <p className="card-title-desc">
                                    Select Any One
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                            <Row>
                              {newProductData &&
                                newProductData.map((product, index) => (
                                  //console.log(this.state.selectedPackage),
                                  <Col lg="4" sm="4" key={product.id}>
                                    <Card
                                      style={
                                        this.state.selectedPackage != "" &&
                                        this.state.selectedPackage == product.id
                                          ? {
                                              border: "3px solid #34c38f",
                                              minHeight: "100%",
                                            }
                                          : { minHeight: "100%" }
                                      }
                                    >
                                      <CardBody className="pt-5 text-center">
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            <h5>{product.name}</h5>
                                            <p className="text-muted">
                                              {product.description}
                                            </p>
                                          </div>
                                          <div className="ms-3">
                                            {/* <i
                                      className={
                                        "bx " + this.props.pricing.icon + " h1 text-primary"
                                      }
                                    /> */}
                                          </div>
                                        </div>
                                        <div className="py-4">
                                          <h2>
                                            <sup>
                                              <small>$</small>
                                            </sup>{" "}
                                            {product.metadata.price}/{" "}
                                            <span className="font-size-13">
                                              Per Month
                                            </span>
                                          </h2>
                                        </div>
                                        <div className="plan-features mt-3">
                                          <p>
                                            <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                                            {product.metadata.packs} Packs
                                            allowed
                                          </p>
                                          <p>
                                            <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                                            {product.metadata.items} Items
                                            allowed per pack
                                          </p>
                                        </div>
                                      </CardBody>
                                      <CardFooter className="bg-transparent border-top text-muted p-3">
                                        <div className="text-center">
                                          <Link
                                            to="#"
                                            className="btn btn-info"
                                            onClick={() => {
                                              this.selectPackage(
                                                product,
                                                index
                                              );
                                            }}
                                          >
                                            <i className="mdi mdi-check-circle-outline text-default" />{" "}
                                            Select Package
                                          </Link>
                                          {/* <CheckoutForm pricing={product} name={this.state.name} email={this.state.email} /> */}
                                        </div>
                                      </CardFooter>
                                    </Card>
                                  </Col>
                                ))}
                            </Row>
                            <Card className="plan-box mt-3">
                              <CardBody className="d-flex justify-content-between">
                                <div className="text-start">
                                  <Link
                                    to="#"
                                    className="btn btn-dark"
                                    onClick={() => {
                                      this.toggleTab("1");
                                    }}
                                  >
                                    Back
                                  </Link>
                                </div>
                                <div className="text-end">
                                  {/* <button
                                className="btn btn-info"
                                onClick={() => {
                                  this.toggleTab("1")
                                }}
                              >
                                Create Tour
                              </button> */}
                                  {this.state.selectedProduct ||
                                  productDetails ? (
                                    // <CheckoutForm
                                    //   pricing={
                                    //     this.state.selectedProduct
                                    //       ? this.state.selectedProduct
                                    //       : productDetails
                                    //   }
                                    //   name={this.state.name}
                                    //   email={this.state.email}
                                    //   tourData={this.state.tourData}
                                    //   productId={this.state.productNum}
                                    //   onSubscribe={event => {
                                    //     event
                                    //       ? this.setState({ isLoading: true })
                                    //       : null;
                                    //   }}
                                    // />
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                      onClick={() => {
                                        this.makePayment();
                                      }}
                                    >
                                      Subscribe & Update Tour
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                          </TabPane>
                        </TabContent>
                      </div>
                    </Container>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {/* </Form> */}
      </React.Fragment>
    );
  }
}

EditTour.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  details: PropTypes.object,
  product: PropTypes.object,
};

const mapStateToProps = state => {
  const { error, success, details } = state.Tour;
  const { subscribe, product } = state.StripeProduct;
  return { error, success, details, subscribe, product };
};

export default withRouter(
  connect(mapStateToProps, {
    createTour,
    editTour,
    createTourError,
    createTourSuccess,
    getTourDetails,
    getStripeProduct,
    createSubscription,
  })(EditTour)
);
