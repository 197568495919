import { takeEvery, put, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER, REGISTER_USER_DETAILS } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper";

import { loginSuccess, logoutUserSuccess, apiError } from "./../login/actions";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    //console.log(user);
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      if (user.password) {
        var response = yield call(
          fireBaseBackend.registerUser,
          user.firstname,
          user.lastname,
          user.email,
          user.phone,
          user.password
        );
        //console.log("RESPPPPPPPPPP", response);
        if (response) {
          yield put(
            registerUserSuccessful(
              "Thank you for signin up ! Please check your email for verification link"
            )
          );
          history.push("/login");
        } else {
          yield put(registerUserFailed(response));
        }
      } else {
        var response = yield call(
          fireBaseBackend.addNewUserOnRegister,
          user.uid,
          user.firstname,
          user.lastname,
          user.email,
          user.phone
        );

        if (response) {
          yield put(loginSuccess(response));
          history.push("/dashboard");
          // }else{
          //   console.log(response)
          //   yield put(registerUserFailed(response));
        }
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user);
      // yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user);
      // yield put(registerUserSuccessful(response));
    }
  } catch (error) {
    //console.log(error);
    yield put(registerUserFailed(error));
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export default accountSaga;
