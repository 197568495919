const { GET_STRIPE_PRODUCT, GET_STRIPE_PRODUCT_SUCCESS, GET_STRIPE_PRODUCT_ERROR, CREATE_SUBSCRIPTION_SUCCESS, CREATE_SUBSCRIPTION_ERROR, CREATE_SUBSCRIPTION } = require("./actionTypes");

const INIT_STATE = {
    product: [],
    subscribe: {},
    error: {},
    loading:false
}

const StripeProduct = (state = INIT_STATE , action) => {
    switch(action.type){
        case GET_STRIPE_PRODUCT : 
            return {
                ...state,
                loading: false
            }
        case GET_STRIPE_PRODUCT_SUCCESS : 
            return {
                ...state,
                product : action.payload,
                loading: true
            }
        case GET_STRIPE_PRODUCT_ERROR : 
            return {
                ...state ,
                error : action.payload
            }
        case CREATE_SUBSCRIPTION_SUCCESS : 
            return {
                ...state ,
                subscribe : action.payload,
                loading: true
            }
        case CREATE_SUBSCRIPTION_ERROR : 
            return {
                ...state,
                error : action.payload
            }
        default :
            return state
    }
}

export default StripeProduct;