const {
  CREATE_TOUR,
  CREATE_TOUR_ERROR,
  CREATE_TOUR_SUCCESS,
  EDIT_TOUR,
  GET_TOUR_LIST,
  GET_TOUR_LIST_SUCCESS,
  GET_TOUR_LIST_ERROR,
  GET_TOUR_DETAILS,
  GET_TOUR_DETAILS_SUCCESS,
  GET_TOUR_DETAILS_ERROR,
  EDIT_TOUR_SUCCESS,
  EDIT_TOUR_ERROR,
} = require("./actionTypes");
const loading = true;
export const createTour = (tour, history) => ({
  type: CREATE_TOUR,
  payload: { tour, history, loading },
});

export const createTourSuccess = tour => ({
  type: CREATE_TOUR_SUCCESS,
  payload: { tour, loading: false },
});

export const createTourError = error => ({
  type: CREATE_TOUR_ERROR,
  payload: error,
});

export const editTour = (tour, history) => ({
  type: EDIT_TOUR,
  payload: { tour, history },
});
export const editTourSuccess = tour => ({
  type: EDIT_TOUR_SUCCESS,
  payload: { tour },
});

export const editTourError = err => ({
  type: EDIT_TOUR_ERROR,
  payload: err,
});

export const getTours = () => ({ type: GET_TOUR_LIST });

export const getToursSuccess = tour => ({
  type: GET_TOUR_LIST_SUCCESS,
  payload: tour,
});

export const getToursError = error => ({
  type: GET_TOUR_LIST_ERROR,
  payload: error,
});

export const getTourDetails = tour => ({
  type: GET_TOUR_DETAILS,
  payload: tour,
});

export const getToursDetailsSuccess = tour => ({
  type: GET_TOUR_DETAILS_SUCCESS,
  payload: tour,
});

export const getToursDetailsError = error => ({
  type: GET_TOUR_DETAILS_ERROR,
  payload: error,
});
