import { CREATE_SUBSCRIPTION, CREATE_SUBSCRIPTION_ERROR, CREATE_SUBSCRIPTION_SUCCESS, GET_STRIPE_PRODUCT, GET_STRIPE_PRODUCT_ERROR, GET_STRIPE_PRODUCT_SUCCESS } from "./actionTypes";
let loading = true
export const getStripeProduct = () => ({
    type : GET_STRIPE_PRODUCT
})

export const getStripeProductSuccess = product => ({
    type : GET_STRIPE_PRODUCT_SUCCESS,
    payload : product
})

export const getStripeProductError = error => ({
    type : GET_STRIPE_PRODUCT_ERROR,
    error : error
})

export const createSubscription = (data , history) => ({
    type : CREATE_SUBSCRIPTION , payload : {data , history,loading}
})

export const createSubscriptionSuccess = subscribe => ({
    type : CREATE_SUBSCRIPTION_SUCCESS,
    payload : subscribe,
    loading:false
})

export const createSubscriptionError = error => ({
    type : CREATE_SUBSCRIPTION_ERROR,
    error : error
})