import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  createTour,
  createTourSuccess,
  createTourError,
} from "../../../store/tour/actions";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    var metadata = "";
    var subscriptiondata = "";
    fetch("https://us-central1-loadout-db.cloudfunctions.net/stripeSession", {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImVlMWI5Zjg4Y2ZlMzE1MWRkZDI4NGE2MWJmOGNlY2Y2NTliMTMwY2YiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA1OTAyOTI4MDY5MjkwMzUzMzEyIiwiaGQiOiJpbnR1aXRpb25zb2Z0ZWNoLmNvbS5hdSIsImVtYWlsIjoicGF5YWxAaW50dWl0aW9uc29mdGVjaC5jb20uYXUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6Ikk1M1A2ZDJIemphYnp5R2hzckc3ZnciLCJpYXQiOjE2NjYxNjkxMTgsImV4cCI6MTY2NjE3MjcxOCwianRpIjoiOTQ3ZmM5ZDJlYWNiOGI4ZGZiZWM5OTRmZDY4MTc4ZWUyOWVmZWM2YSJ9.qgo4w00jNy_7hf5uHgw3LhfWCmJP8aeg17G8VShbo2sqZgFyokkXV0QRADCVzbiy9yzyH-zyImQRlygrDNzZ9nqaTcO-tMIwI7ud5bkKyTRZPL9Tw07k83bwv2JhzM6kzHEA5qISbTx71CORCvCM7W5c2jbhcrJv9_MGLmJxTH38LsVM2SHjmHJ_23_xwMTmXzeCHWSATQryD20r03yXkWrfJt0Y2ABqdsWuscoh1IQB2kK46L5LAjOPe_i8NQqHLXDUWA84jw189v_sQS-215QkJ8oR_0RctdGdV4lp58weiCsRLtZ20Tk6Jf3K0VhD4S3yxpt3Imd1QhpB0lmOeg",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ data: { sessionId: this.props.match.params.id } }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.data.subscription) {
          this.setState({ tourdata: response.data.metadata });
          this.setState({ productId: response.data.metadata.productId });
          fetch(
            "https://us-central1-loadout-db.cloudfunctions.net/getStripeSubscription",
            {
              headers: {
                Authorization:
                  "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImVlMWI5Zjg4Y2ZlMzE1MWRkZDI4NGE2MWJmOGNlY2Y2NTliMTMwY2YiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA1OTAyOTI4MDY5MjkwMzUzMzEyIiwiaGQiOiJpbnR1aXRpb25zb2Z0ZWNoLmNvbS5hdSIsImVtYWlsIjoicGF5YWxAaW50dWl0aW9uc29mdGVjaC5jb20uYXUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6Ikk1M1A2ZDJIemphYnp5R2hzckc3ZnciLCJpYXQiOjE2NjYxNjkxMTgsImV4cCI6MTY2NjE3MjcxOCwianRpIjoiOTQ3ZmM5ZDJlYWNiOGI4ZGZiZWM5OTRmZDY4MTc4ZWUyOWVmZWM2YSJ9.qgo4w00jNy_7hf5uHgw3LhfWCmJP8aeg17G8VShbo2sqZgFyokkXV0QRADCVzbiy9yzyH-zyImQRlygrDNzZ9nqaTcO-tMIwI7ud5bkKyTRZPL9Tw07k83bwv2JhzM6kzHEA5qISbTx71CORCvCM7W5c2jbhcrJv9_MGLmJxTH38LsVM2SHjmHJ_23_xwMTmXzeCHWSATQryD20r03yXkWrfJt0Y2ABqdsWuscoh1IQB2kK46L5LAjOPe_i8NQqHLXDUWA84jw189v_sQS-215QkJ8oR_0RctdGdV4lp58weiCsRLtZ20Tk6Jf3K0VhD4S3yxpt3Imd1QhpB0lmOeg",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                data: { subscriptionId: response.data.subscription },
              }),
            }
          )
            .then(resp => resp.json())
            .then(resp => {
              this.setState({ subscribe: resp.data }, () => {
                // if (localStorage.getItem("tour_img")) {
                //   // var photo = localStorage.getItem("tour_img");
                //   // var newobj = { ...this.state.tourdata, photo: photo };
                //   console.log(newobj);
                //   var data = {
                //     tour: newobj,
                //     subscribe: this.state.subscribe,
                //     productId: this.state.productId,
                //   };
                // } else {
                var data = {
                  tour: this.state.tourdata,
                  subscribe: this.state.subscribe,
                  productId: this.state.productId,
                  // };
                };

                this.props.createTour(data, this.props.history);
              });
            });
        } else {
          this.props.history.push("/new-tour");
        }
      });
  }

  render() {
    return (
      <>
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      </>
    );
  }
}

Subscription.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { error, success, details, loading } = state.Tour;
  const { subscribe } = state.StripeProduct;
  return { error, success, loading, subscribe };
};

export default withRouter(
  connect(mapStateToProps, {
    createTour,
    createTourError,
    createTourSuccess,
  })(Subscription)
);
