import { call, put, takeEvery } from "redux-saga/effects";
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import {
  createPackError,
  createPackSuccess,
  editPackError,
  editPackSuccess,
  getPackDetailsError,
  getPackDetailsSuccess,
  getPackListError,
  getPackListSuccess,
  tourPackListError,
  tourPackListSuccess,
} from "./actions";
import {
  CREATE_PACK,
  EDIT_PACK,
  GET_PACK_DETAILS,
  GET_PACK_LIST,
  TOUR_PACK_LIST,
} from "./actionTypes";

const fireBaseBackend = getFirebaseBackend();

function* getPackList() {
  try {
    const responce = yield call(fireBaseBackend.getPack);
    if (responce) {
      for (let element of responce) {
        const resp = yield call(fireBaseBackend.getTourDetails, element.tUID);
        if (resp) {
          element.tourname = resp.det
            ? resp.det.nm + " - " + resp.det.snm
            : "NA";
          element.status = resp.det ? resp.det.st : false;
        }
      }
      yield put(getPackListSuccess(responce));
    } else {
      yield put(getPackListError(responce));
    }
  } catch (error) {
    yield put(getPackListError(error));
  }
}

function* getTourPackList({ payload: id }) {
  try {
    var data = [];
    //console.log(id);
    const responce = yield call(fireBaseBackend.getPack, id);
    if (responce) {
      for (let element of responce) {
        if (element.tUID == id) {
          const resp = yield call(fireBaseBackend.getTourDetails, element.tUID);
          if (resp) {
            element.tourname = resp.det
              ? resp.det.nm + " - " + resp.det.snm
              : "NA";
            element.status = resp.det ? resp.det.st : false;
            data.push(element);
          }
        }
      }
      //console.log("packs list data ===============", responce);
      yield put(tourPackListSuccess(data));
    } else {
      yield put(tourPackListError(responce));
    }
  } catch (error) {
    yield put(tourPackListError(error));
  }
}

function* getPackDetails({ payload: pack }) {
  try {
    //console.log(pack);
    const responce = yield call(fireBaseBackend.getPackDetails, pack);
    //console.log(responce);
    if (responce) {
      yield put(getPackDetailsSuccess(responce));
    } else {
      yield put(getPackDetailsError("Something went wrong !!"));
    }
  } catch (err) {
    yield put(getPackDetailsError(err));
  }
}

function* createPack({ payload: { pack, history } }) {
  try {
    const responce = yield call(fireBaseBackend.createPack, pack);
    if (responce) {
      yield put(createPackSuccess("Pack create successfully!!"));
      history.push("/view-pack/" + responce);
    } else {
      yield put(createPackError(responce));
    }
  } catch (error) {
    yield put(createPackError(error));
  }
}

function* editPackDetails({ payload: { pack, history } }) {
  try {
    const responce = yield call(fireBaseBackend.editPack, pack);
    if (responce) {
      yield put(editPackSuccess(responce));
      history.push("/view-tour/" + pack.tour);
    } else {
      yield put(editPackError("Something went wrong!!"));
      history.push("/view-tour/" + pack.tour);
    }
  } catch (err) {
    yield put(editPackError(err));
  }
}

function* packSaga() {
  yield takeEvery(GET_PACK_LIST, getPackList);
  yield takeEvery(CREATE_PACK, createPack);
  yield takeEvery(EDIT_PACK, editPackDetails);
  yield takeEvery(GET_PACK_DETAILS, getPackDetails);
  yield takeEvery(TOUR_PACK_LIST, getTourPackList);
}

export default packSaga;
