import firebase from "firebase/compat/app";
// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import { ref } from "yup";

var provider = "";
var storage = "";
var storageRef = "";
var userId = "";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      const app = firebase.initializeApp(firebaseConfig);
      provider = new firebase.auth.OAuthProvider("apple.com");
      //console.log("provider==>", provider);
      storage = app.storage();
      //console.log("storage==>", storage);
    }
  }

  async waitForAuthInit() {
    let user;
    await new (resolve => {
      user = firebase.auth().onAuthStateChanged(_ => resolve());
    })();
    (await user)();
  }

  async getCurrentUser() {
    try {
      await this.waitForAuthInit();
      return await firebase.auth().currentUser;
    } catch (err) {
      //console.log("Failed to get current user...", err);
      return null;
    }
  }

  onAuthStateChanged = authstate => {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          if (user.emailVerified) {
            firebase
              .database()
              .ref()
              .child("/users/" + user.uid)
              .once("value", snap => {
                //console.log(snap.val());
                localStorage.removeItem("authUser");
                localStorage.removeItem("authorized");
                localStorage.removeItem("Uid");
                localStorage.setItem("authUser", JSON.stringify(snap.val()));
                localStorage.setItem("Uid", user.uid);
                firebase
                  .database()
                  .ref()
                  .child("/data/admin/")
                  .on("value", snap => {
                    let count = 0;
                    snap.forEach(data => {
                      if (data.key == user.uid) {
                        count++;
                      } else {
                        count = 0;
                      }
                    });
                    if (count == 0) {
                      firebase
                        .database()
                        .ref()
                        .child("/data/admin/" + user.uid)
                        .set(0);
                    }
                  });
                resolve(authstate);
              });
          } else {
            localStorage.removeItem("authUser");
            localStorage.removeItem("Uid");
            resolve(authstate);
          }
        } else {
          localStorage.removeItem("authUser");
          localStorage.removeItem("Uid");
          resolve(authstate);
        }
      });
    });
  };

  getUserDetails = uid => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref()
        .child("/users/" + uid)
        .once("value")
        .then(user => {
          //console.log(user.val());
          resolve(this.onAuthStateChanged(user.val()));
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Registers the user with given details
   */
  registerUser = (firstname, lastname, email, phone, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            const actionCodeSettings = {
              url: "https://loadout-webdev.web.app/login",
              handleCodeInApp: true,
            };

            //console.log(firebase.auth().currentUser);
            firebase
              .auth()
              .currentUser.sendEmailVerification(actionCodeSettings)
              .then(res => {
                //console.log("Verification email sent!");
                resolve(
                  this.addNewUserOnRegister(
                    user.user.uid,
                    firstname,
                    lastname,
                    email,
                    phone
                  )
                );
              });
          },
          error => {
            if (
              error.message.includes("EMAIL_EXISTS") ||
              error.message.includes("email-already-in-use")
            ) {
              reject("Email already used. Go to login page.");
            } else if (
              error.message.includes("ERROR_WRONG_PASSWORD") ||
              error.message.includes("wrong-password")
            ) {
              reject("Wrong email/password combination.");
            } else if (error.message.includes("auth/weak-password")) {
              reject("Password should be atleast of 6 digits");
            } else {
              reject(error.message);
            }
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, fn, ln, phn, file) => {
    return new Promise((resolve, reject) => {
      storageRef = storage.ref();
      var profileUrl = "";
      if (file != "" && file.name != undefined) {
        const reference = storageRef.child(
          `profileImages/${firebase.auth().currentUser.uid}/${file.name}`
        );
        const uploadTask = reference.put(file);
        uploadTask.on(
          "state_changed",
          snapshot => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            //console.log(percent);
          },
          err =>
            //console.log(err),
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                //console.log("File available at", downloadURL);
                profileUrl = downloadURL;

                const pub = {
                  fn: fn,
                  ln: ln,
                  pURL: profileUrl,
                };
                const pri = {
                  eml: email,
                  phn: phn,
                };
                const sub = {
                  crtd: firebase.database.ServerValue.TIMESTAMP,
                  sty: 0,
                };

                firebase
                  .database()
                  .ref()
                  .child("/users/" + firebase.auth().currentUser.uid + "/pub")
                  .set(pub);
                firebase
                  .database()
                  .ref()
                  .child("/users/" + firebase.auth().currentUser.uid + "/pvt")
                  .set(pri);
                firebase
                  .database()
                  .ref()
                  .child("/users/" + firebase.auth().currentUser.uid + "/sub")
                  .set(sub);

                resolve(this.onAuthStateChanged(firebase.auth().currentUser));
              });
            }
        );
      } else {
        const pub = {
          fn: fn,
          ln: ln,
          pURL: "",
        };
        const pri = {
          eml: email,
          phn: phn,
        };
        const sub = {
          crtd: firebase.database.ServerValue.TIMESTAMP,
          sty: 0,
        };

        firebase
          .database()
          .ref()
          .child("/users/" + firebase.auth().currentUser.uid + "/pub")
          .set(pub);
        firebase
          .database()
          .ref()
          .child("/users/" + firebase.auth().currentUser.uid + "/pvt")
          .set(pri);
        firebase
          .database()
          .ref()
          .child("/users/" + firebase.auth().currentUser.uid + "/sub")
          .set(sub);

        resolve(this.onAuthStateChanged(firebase.auth().currentUser));
      }
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            //console.log("user info", user);
            resolve(this.onAuthStateChanged(firebase.auth().currentUser));
          },
          error => {
            //console.log(error);
            if (
              error.message.includes("EMAIL_EXISTS") ||
              error.message.includes("email-already-in-use")
            ) {
              reject("Email already used. Go to login page.");
            } else if (
              error.message.includes("ERROR_WRONG_PASSWORD") ||
              error.message.includes("wrong-password")
            ) {
              reject("Wrong email/password combination.");
            } else if (error.message.includes("auth/user-not-found")) {
              reject("User Not Found");
            } else {
              reject(error.message);
            }
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .fetchProvidersForEmail(email)
        .then(providers => {
          if (providers.length != 0) {
            if (providers[0] == "password") {
              firebase
                .auth()
                .sendPasswordResetEmail(
                  email
                  //   , {
                  //   url:
                  //     window.location.protocol + "//" + window.location.host + "/login",
                  // }
                )
                .then(() => {
                  resolve(true);
                })
                .catch(error => {
                  reject(this._handleError(error));
                });
              resolve(true);
            } else {
              reject("User Not Found");
            }
          } else {
            reject("User Not Found");
          }
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {};
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(
        data.idToken,
        data.token
      );
      // } else if (type === "facebook") {
      //   credential = firebase.auth.FacebookAuthProvider.credential(data.token)
    } else if (type === "apple") {
      credential = data;
    }
    //console.log(credential);
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase
          .auth()
          .signInWithCredential(credential)
          .then(user => {
            resolve(this.addNewUserToFirestore(user, type));
          })
          .catch(error => {
            reject(this._handleError(error));
          });
      } else {
        reject(this._handleError("Error"));
      }
    });
  };

  addNewUserToFirestore = async (user, type) => {
    const collection = firebase.database().ref("/users");
    if (type == "apple") {
      const profile = user.user.multiFactor.user;
      const pub = {
        fn: profile.displayName ? profile.displayName : profile.first_name,
        ln: "",
        pURL: profile.picture ? profile.picture : "",
      };
      const pri = {
        eml: profile.email,
        phn: "",
      };

      const sub = {
        crtd: firebase.database.ServerValue.TIMESTAMP,
        sty: 0,
      };

      const details = {
        uid: firebase.auth().currentUser.uid,
        firstName: profile.displayName
          ? profile.displayName
          : profile.first_name,
        lastName: profile.family_name ? profile.family_name : "",
        email: profile.email,
        phone: "",
        fullName: profile.displayName,
        authProvider: "apple",
        pURL: profile.picture ? profile.picture : "",
      };
      return { details };
    } else {
      const { profile } = user.additionalUserInfo;
      const pub = {
        fn: profile.given_name ? profile.given_name : profile.first_name,
        ln: profile.family_name ? profile.family_name : profile.last_name,
        pURL: profile.picture ? profile.picture : "",
      };
      const pri = {
        eml: profile.email,
        phn: "",
      };

      const sub = {
        crtd: firebase.database.ServerValue.TIMESTAMP,
        sty: 0,
      };

      const details = {
        uid: firebase.auth().currentUser.uid,
        firstName: profile.given_name ? profile.given_name : profile.first_name,
        lastName: profile.family_name ? profile.family_name : profile.last_name,
        email: profile.email,
        phone: "",
        fullName: profile.given_name
          ? profile.given_name
          : profile.first_name + " " + profile.family_name
          ? profile.family_name
          : profile.last_name,
        authProvider: "google",
        pURL: profile.picture ? profile.picture : "",
      };
      return { details };
    }
  };

  addNewUser = (uid, name, email, provider) => {
    const collection = firebase.database().ref("/users");
    const details = {
      uid: uid,
      name: name ? name : "",
      email: email,
      picture: "",
      authProvider: provider,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    };
    firebase
      .database()
      .ref()
      .child("/users/" + firebase.auth().currentUser.uid)
      .set(details);
    return { details };
  };

  addNewUserOnRegister = (uid, firstname, lastname, email, phone) => {
    return new Promise((resolve, reject) => {
      const collection = firebase.database().ref("/users");
      const pub = {
        fn: firstname ? firstname : "",
        ln: lastname ? lastname : "",
        pURL: "",
      };
      const pri = {
        eml: email,
        phn: phone,
      };

      const sub = {
        crtd: firebase.database.ServerValue.TIMESTAMP,
        sty: 0,
      };

      const details = {
        uid: uid,
        firstName: firstname ? firstname : "",
        lastName: lastname ? lastname : "",
        email: email,
        phone: phone,
        fullName: firstname + " " + lastname,
        authProvider: "local",
      };

      firebase
        .database()
        .ref()
        .child("/users/" + firebase.auth().currentUser.uid + "/pub")
        .set(pub);
      firebase
        .database()
        .ref()
        .child("/users/" + firebase.auth().currentUser.uid + "/pvt")
        .set(pri);
      firebase
        .database()
        .ref()
        .child("/users/" + firebase.auth().currentUser.uid + "/sub")
        .set(sub);
      // return { details }
      resolve(this.onAuthStateChanged(details));
    });
  };

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Get stripe products from firebase functions
   */
  getProducts() {
    return new Promise((resolve, reject) => {
      const fc = firebase.functions();
      const data = fc.httpsCallable("getAllProducts");
      data({})
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Create Subscription with firebase function
   */
  createSubscription(subscribe) {
    return new Promise((resolve, reject) => {
      //console.log(subscribe);
      const fc = firebase.functions();
      const data = fc.httpsCallable("createCheckoutSession");
      data(subscribe)
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Create Tour
   * @param {*} data
   */
  newTour(data, stripe, id) {
    return new Promise((resolve, reject) => {
      //console.log(data, "data");
      if (data.photo != "" && data.photo != undefined) {
        storageRef = storage.ref();
        var profileUrl = "";
        const reference = storageRef.child(
          `tourImages/${firebase.auth().currentUser.uid}/${data.photo}`
        );
        const uploadTask = reference.put(data.photo);
        uploadTask.on(
          "state_changed",
          snapshot => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          err => () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              profileUrl = downloadURL;
              var det = {
                nm: data.title,
                snm: data.subName,
                pURL: profileUrl,
                st: stripe.status == "active" ? true : false,
                own: firebase.auth().currentUser.uid,
                sty: id,
              };

              var newkey = data.id
                ? data.id
                : firebase.database().ref().child("tours").push().key;
              firebase
                .database()
                .ref()
                .child("/tours/" + newkey + "/det/")
                .set(det);
              firebase
                .database()
                .ref()
                .child("/tours/" + newkey + "/stp/")
                .set(stripe);

              const usr = firebase
                .database()
                .ref()
                .child(
                  "/tours/" + newkey + "/usr/" + firebase.auth().currentUser.uid
                )
                .set(0);
              const sub = firebase
                .database()
                .ref()
                .child("/tours/" + newkey + "/sub/")
                .set({
                  st: stripe.status == "active" ? 0 : 1,
                  sty: id,
                  crtd: stripe.current_period_start,
                  lst: stripe.start_date,
                  nxt: stripe.current_period_end,
                });
              const tor = firebase
                .database()
                .ref()
                .child(
                  "/users/" + firebase.auth().currentUser.uid + "/tor/" + newkey
                )
                .set(0);
              resolve(newkey);
              // resolve(this.onAuthStateChanged(firebase.auth().currentUser));
            });
          }
        );
      } else {
        const det = {
          nm: data.title,
          snm: data.subName,
          pURL: data.photo ? data.photo : "",
          st: stripe.status == "active" ? true : false,
          own: firebase.auth().currentUser.uid,
          sty: id,
        };
        var newkey = data.id
          ? data.id
          : firebase.database().ref().child("tours").push().key;
        const resp = firebase
          .database()
          .ref()
          .child("/tours/" + newkey + "/det/")
          .set(det);
        firebase
          .database()
          .ref()
          .child("/tours/" + newkey + "/stp/")
          .set(stripe);
        const usr = firebase
          .database()
          .ref()
          .child("/tours/" + newkey + "/usr/" + firebase.auth().currentUser.uid)
          .set(0);
        const sub = firebase
          .database()
          .ref()
          .child("/tours/" + newkey + "/sub/")
          .set({
            st: stripe.status == "active" ? 0 : 1,
            sty: id,
            crtd: stripe.current_period_start,
            lst: stripe.start_date,
            nxt: stripe.current_period_end,
          });
        const tor = firebase
          .database()
          .ref()
          .child("/users/" + firebase.auth().currentUser.uid + "/tor/" + newkey)
          .set(0);
        resolve(newkey);
      }
    });
  }

  /**
   * Get Tours List
   */
  getTours() {
    let uid = localStorage.getItem("Uid");
    //  console.log(uid, "usr id");
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref()
        .child("/users/" + uid + "/tor")
        .on("value", snap => {
          if (snap) {
            var res = [];
            var resp = new Promise((resolve, reject) => {
              snap.forEach((dv, index, array) => {
                firebase
                  .database()
                  .ref()
                  .child("/tours/" + dv.key)
                  // .orderBy('nm','desc')
                  .on("value", snapshot => {
                    // console.log("snapshot", snapshot.val());
                    const dataVal = snapshot.val();
                    const newobj = { det: dataVal.det, sub: dataVal.sub };
                    // console.log(newobj);
                    res.push({
                      ...newobj,
                      id: dv.key,
                    });
                    if (res.length == snap.numChildren()) {
                      resolve(res);
                    }
                  });
              });
            });
          } else {
            // console.log("ERE I AM");
          }
          resp
            .then(result => {
              resolve(result);
            })
            .catch(err => {
              reject(err);
            });
        });
    });
  }
  /**
   * Get ROut details by id
   */

  getTourDetails(id) {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref()
        .child("/tours/" + id)
        .on("value", snap => {
          //console.log("tour details", snap.val());

          resolve(snap.val());
        });
    });
  }

  /**
   * editTour
   */
  editTour(data, id) {
    return new Promise((resolve, reject) => {
      if (data.photo != "" && data.photo != undefined) {
        storageRef = storage.ref();
        var profileUrl = "";
        const reference = storageRef.child(
          `tourImages/${firebase.auth().currentUser.uid}/${data.photo}`
        );
        const uploadTask = reference.put(data.photo);
        uploadTask.on(
          "state_changed",
          snapshot => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            //console.log(percent);
          },
          err =>
            //console.log(err),
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                //console.log("File available at", downloadURL);
                profileUrl = downloadURL;

                const det = {
                  nm: data.title,
                  snm: data.subName,
                  pURL: profileUrl,
                  st: true,
                  own: firebase.auth().currentUser.uid,
                  sty: id,
                };
                var newkey = data.id;
                const resp = firebase
                  .database()
                  .ref()
                  .child("/tours/" + newkey + "/det/")
                  .set(det);
                resolve(det);
              });
            }
        );
      } else {
        const det = {
          nm: data.title,
          snm: data.subName,
          pURL: data.photo,
          st: true,
          own: firebase.auth().currentUser.uid,
          sty: id,
        };
        var newkey = data.id;
        const resp = firebase
          .database()
          .ref()
          .child("/tours/" + newkey + "/det/")
          .set(det);
        resolve(det);
      }
    });
  }

  /**
   * Create Pack
   */

  createPack(data) {
    let uid = localStorage.getItem("Uid");
    //console.log("data==>", data);
    return new Promise((resolve, reject) => {
      const det = {
        nm: data.name,
        cat: data.category,
        own: uid,
        tUID: data.tour,
      };
      var newkey = firebase.database().ref().child("packs").push().key;
      firebase
        .database()
        .ref()
        .child("/packs/" + newkey + "/det")
        .set(det);
      firebase
        .database()
        .ref()
        .child("/packs/" + newkey + "/usr/" + uid)
        .set(0);
      firebase
        .database()
        .ref()
        .child("/users/" + uid + "/pck/" + data.tour + "/" + newkey)
        .set(0);
      //// console.log("det=", det);
      resolve(newkey);
    });
  }

  /**
   * Edit pack details
   */
  editPack(data) {
    return new Promise((resolve, reject) => {
      const det = {
        nm: data.name,
        cat: data.category,
        own: firebase.auth().currentUser.uid,
        tUID: data.tour,
      };
      var newkey = data.id;
      firebase
        .database()
        .ref()
        .child("/packs/" + newkey + "/det")
        .set(det);
      resolve(det);
    });
  }

  /**
   * Get Packs List
   */
  getPack() {
    let uid = localStorage.getItem("Uid");
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref()
        .child("/packs")
        .on("value", snapshot => {
          var res = [];
          var resp = new Promise((resolve, reject) => {
            snapshot.forEach(data => {
              const dataVal = data.val().det;
              //console.log(dataVal);
              if (dataVal) {
                if (dataVal.own == uid) {
                  //console.log(dataVal);
                  var tours = {};
                  res.push({
                    ...dataVal,
                    id: data.key,
                  });
                  resolve(res);
                }
              }
            });
          });
          resp.then(result => {
            resolve(result);
          });
        });
    });
  }

  /**
   * Get Packs List
   */
  getTourPack(id) {
    let uid = localStorage.getItem("Uid");
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref()
        .child("/packs")
        .on("value", snapshot => {
          var res = [];
          var resp = new Promise((resolve, reject) => {
            snapshot.forEach(data => {
              const dataVal = data.val().det;
              if (dataVal) {
                // if (dataVal.tUID == id) {
                //// console.log(dataVal);
                var tours = {};
                res.push({
                  ...dataVal,
                  id: data.key,
                });
                resolve(res);
                // }
              }
            });
          });
          resp.then(result => {
            resolve(result);
          });
        });
    });
  }

  /**
   * Get Pack Details
   */
  getPackDetails(id) {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref()
        .child("/packs/" + id)
        .on("value", snap => {
          resolve(snap.val());
        });
    });
  }

  /**
   * Edit Item Details
   */
  editItem(data) {
    //console.log("--------", data);
    return new Promise((resolve, reject) => {
      const det = {
        id: data.id,
        own: firebase.auth().currentUser.uid,
        st: 0,
        trst: "",
        col: data.color,
        dW: data.dW,
        dX: data.dX,
        dY: data.dY,
        dZ: data.dZ,
        nm: data.name,
        pURL: "",
        st: 0,
        trst: "",
      };
      var newkey = data.id;
      firebase
        .database()
        .ref()
        .child("/items/" + newkey + "/det")
        .set(det);

      resolve(det);
    });
  }

  /**
   * Get Item Details
   */
  getItemDetails(id) {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref()
        .child("/items/" + id)
        .on("value", snap => {
          //console.log(snap.val());
          resolve(snap.val());
        });
    });
  }

  /**
   * Get Items
   */
  async getItem() {
    let uid = localStorage.getItem("Uid");
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref()
        .child("/users/" + uid + "/itm")
        .on("value", snap => {
          var res = [];
          var resp = new Promise((resolve, reject) => {
            snap.forEach((dv, index, array) => {
              firebase
                .database()
                .ref()
                .child("/items/" + dv.key)
                .on("value", snapshot => {
                  const dataVal = snapshot.val().det;
                  res.push({
                    ...dataVal,
                    id: dv.key,
                  });
                  if (res.length == snap.numChildren()) {
                    resolve(res);
                  }
                });
            });
          });
          //console.log("resp===>", resp);
          resp.then(result => {
            //console.log("result===00", result);
            resolve(result);
          });
        });
    });
  }

  /**
   * Create New Item
   */
  createItem(data) {
    return new Promise((resolve, reject) => {
      const det = {
        own: firebase.auth().currentUser.uid,
        st: 0,
        trst: "",
        col: data.color,
        dW: data.dW,
        dX: data.dX,
        dY: data.dY,
        dZ: data.dZ,
        nm: data.name,
        pURL: "",
        st: 0,
        trst: "",
      };
      var newkey = firebase.database().ref().child("items").push().key;
      firebase
        .database()
        .ref()
        .child("/items/" + newkey + "/det")
        .set(det);
      firebase
        .database()
        .ref()
        .child("/packs/" + newkey + "/itm/" + newkey)
        .set({
          col: data.color,
          st: 0,
          trst: firebase.auth().currentUser.uid,
        });
      firebase
        .database()
        .ref()
        .child("/users/" + firebase.auth().currentUser.uid + "/itm/" + newkey)
        .set(0);
      resolve(det);
    });
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
