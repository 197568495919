import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  SOCIAL_USER_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        registrationError: null,
      };
      break;
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: null,
        registerSuccessMsg: action.payload,
        registrationError: null,
      };
      break;
    case SOCIAL_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: null,
        successMessage: action.payload,
        registrationError: null,
      };
      break;
    case REGISTER_USER_FAILED:
      action.payload != ""
        ? (state = {
            ...state,
            user: null,
            loading: true,
            registrationError: action.payload,
          })
        : (state = {
            ...state,
            user: null,
            loading: true,
          });
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default account;
