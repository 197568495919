import { call, put, takeEvery } from "redux-saga/effects";
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import {
  createSubscriptionError,
  createSubscriptionSuccess,
  getStripeProductError,
  getStripeProductSuccess,
} from "./actions";
import { CREATE_SUBSCRIPTION, GET_STRIPE_PRODUCT } from "./actionTypes";
import {
  createTourSuccess,
  createTourError,
  getToursSuccess,
} from "../tour/actions";

const fireBaseBackend = getFirebaseBackend();

function* getProducts() {
  try {
    const responce = yield call(fireBaseBackend.getProducts);

    if (responce) {
      yield put(getStripeProductSuccess(responce.data.data));
    }
  } catch (error) {
    yield put(getStripeProductError(error));
  }
}

function* createSubscription({ payload: { data, history } }) {
  try {
    const responce = yield call(fireBaseBackend.createSubscription, data);
    if (responce) {
      yield put(createSubscriptionSuccess(responce));
      // console.log(responce);
      if (responce.data && responce.data.url) {
        window.location.href = responce.data.url;
      }
      // var resp = yield call(
      //   fireBaseBackend.newTour,
      //   data.tour,
      //   responce.data,
      //   data.productId
      // );
      // if (resp) {
      //   console.log(resp);
      //   yield put(createTourSuccess("New Tour Created Successfully!!"));
      //   // const rep = yield call(fireBaseBackend.getTours);
      //   // if (rep) {
      //   //   yield put(getToursSuccess(rep));
      //   //   if (data.tour && data.tour.id) {
      //   //   }
      //   //   history.push("/dashboard");
      //   // }
      //   history.push("/view-tour/" + resp);
      // } else {
      //   yield put(createTourError("Something went wrong!!"));
      //   history.push("/new-tour");
      // }
    }
  } catch (error) {
    yield put(createTourError("Something went wrong!!"));
    history.push("/new-tour");
  }
}

function* productSaga() {
  yield takeEvery(GET_STRIPE_PRODUCT, getProducts);
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscription);
}

export default productSaga;
