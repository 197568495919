import React, { Component } from "react";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Get Tours
import { getTours } from "./../../store/tour/actions";
import {
  createPack,
  getPackDetails,
  editPack,
} from "./../../store/pack/actions";

class EditPack extends Component {
  constructor(props) {
    super(props);
    this.state = { tour: "", isLoading: false };
  }

  componentDidMount() {
    this.props.getTours();
    //console.log(this.props.match.params.id);
    this.props.getPackDetails(this.props.match.params.id);
    // this.setState({tour:details && details.det && details.det.tUID})
    if (this.props.location.state && this.props.location.state.tour_id) {
      this.setState({
        tour_id: this.props.location.state.tour_id,
      });
    }
    this.getPackDetails();
  }

  getPackDetails() {
    if (this.props.details.det) {
      //console.log(this.props.details.det);
      const { tUID } = this.props.details.det;
      this.setState({ tour: tUID });
    }
  }

  change = event => {
    //console.log(event.target.value);
    this.setState({ tour: event.target.value });
  };

  render() {
    var tourslist = this.props.tours;
    const { details } = this.props;
    //meta title
    //console.log(this.props);
    document.title = "Edit Pack | Loadout Admin Dashboard";
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Pack" breadcrumbItem="Edit Pack" />
              <Row>
                <Col lg="12" sm="12">
                  <Card>
                    <CardBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          tour:
                            (details && details.det && details.det.tUID) || "",
                          name:
                            (details && details.det && details.det.nm) || "",
                          category:
                            (details && details.det && details.det.cat) || "",
                          id: this.props.match.params.id,
                        }}
                        validationSchema={Yup.object().shape({
                          tour: Yup.string().required("Please Select Tour"),
                          name: Yup.string().required("Please Enter Pack Name"),
                          category: Yup.string().required(
                            "Please Enter Category"
                          ),
                        })}
                        onSubmit={values => {
                          this.setState({ isLoading: true });
                          //console.log(values);
                          this.props.editPack(values, this.props.history);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form>
                            <div>
                              <p className="card-title-desc">
                                Fill all information below
                              </p>
                              <div className="mb-3">
                                <Label>Tour</Label>
                                <select
                                  className={
                                    "form-control" +
                                    (errors.tour && touched.tour
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name="tour"
                                  onChange={this.change}
                                  value={this.state.tour}
                                >
                                  <option value="">Select Tour</option>
                                  {tourslist.map((x, y) =>
                                    x.det.st ? (
                                      <option key={x.id} value={x.id}>
                                        {x.det.nm}
                                        {" - "}
                                        {x.det.snm}
                                      </option>
                                    ) : null
                                  )}
                                  ;
                                </select>
                                <ErrorMessage
                                  name="tour"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label>Name</Label>
                                <Field
                                  name="name"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.name && touched.name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label>Category</Label>
                                <Field
                                  name="category"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.category && touched.category
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="category"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <Row>
                                <Col>
                                  <div className="text-start">
                                    {/* {this.state.tour_id ? (
                                      <Link
                                        to={"/view-tour/" + this.state.tour_id}
                                        className="btn btn-dark"
                                      >
                                        Back
                                      </Link>
                                    ) : ( */}
                                    <Link
                                      to={
                                        "/view-pack/" +
                                        this.props.match.params.id
                                      }
                                      className="btn btn-dark"
                                    >
                                      Back
                                    </Link>
                                    {/* )} */}
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      className="btn btn-info"
                                      type="submit"
                                    >
                                      Edit Pack Details
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

EditPack.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  tours: PropTypes.object,
  createPack: PropTypes.func,
  details: PropTypes.object,
};

const mapStateToProps = state => {
  //console.log(state);
  const { error, success, tours } = state.Tour;
  const { details } = state.Pack;
  return { error, success, tours, details };
};

export default withRouter(
  connect(mapStateToProps, { getTours, createPack, getPackDetails, editPack })(
    EditPack
  )
);
