import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import { userForgetPassword } from "../../store/actions";

import {
  signInWithGoogle,
  registerWithEmailPassword,
  loginInWithEmailAndPassword,
  sendPasswordReset,
} from "../../firebase";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/loadoutlogo.png";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  register = responce => {
    //console.log(responce)
    registerWithEmailPassword(
      responce.username,
      responce.email,
      responce.password
    );
  };

  render() {
    return (
      <React.Fragment>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div> */}
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Loadout.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="#">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title  bg-light">
                            <img src={logo} alt="" className="" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.forgetError}
                        </Alert>
                      ) : null}
                      {this.props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: (this.state && this.state.email) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .required("Please Enter Your Email")
                            .email("Enter Valid Email"),
                        })}
                        onSubmit={values => {
                          this.props.userForgetPassword(
                            values,
                            this.props.history
                          );
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="text-end">
                              <button
                                className="btn btn-primary w-md"
                                type="submit"
                              >
                                Reset
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link to="login" className="fw-medium text-primary">
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} Loadout. Crafted with
                    <i className="mdi mdi-heart text-danger" /> by{" "}
                    <a href="https://www.intuitionsoftech.com.au/">
                      Intuition SofTech
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
};

const mapStateToProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
);
