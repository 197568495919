import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import {
  apiError,
  registerUser,
  socialLogin,
  registerUserFailed,
  registerUserSuccessful,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
//Import config
import { facebook, google } from "../../config";
import { gapi } from "gapi-script";

import AppleLogin from "react-apple-login";
import AppleSignin from "react-apple-signin-auth";
import { appleAuthHelpers } from "react-apple-signin-auth";
import {
  signInWithGoogle,
  registerWithEmailPassword,
  loginInWithEmailAndPassword,
  sendPasswordReset,
  signInApple,
} from "../../firebase";

// import images
import profileImg from "../../assets/images/profile-img.png";
// import logoImg from "../../assets/images/logo.svg";
import logoImg from "../../assets/images/loadoutlogo.png";
import lightlogo from "../../assets/images/loadoutlogo.png";

const getUserName = () => {
  if (localStorage.getItem("authorized")) {
    const obj = JSON.parse(localStorage.getItem("authorized"));
    //console.log(obj);
    return obj;
  }
};
class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      showCPassword: false,
      isLoading: false,
      phoneRegExp:
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    };
  }

  appleLogin = async () => {
    const resp = await signInApple();
    //console.log(resp);
    this.signIn(resp, "apple");
  };

  resetRegisterForm = () => {
    this.props.apiError("");
    this.props.registerUserFailed("");
    const userData = getUserName();
    // //console.log("REGISTER USER DATA", userData.details)
    if (userData) {
      this.setState({
        uid: userData.details.uid,
        firstname: userData.details.firstName,
        lastname: userData.details.lastName,
        profile: userData.details.pURL,
        email: userData.details.email,
        phone: userData.details.phone,
        showpass: false,
      });
    }
  };
  componentDidMount() {
    this.resetRegisterForm();
  }

  useEffect = () => {
    function start() {
      gapi.client.init({
        clientId: google.CLIENT_ID,
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("nextProps", nextProps);
    this.resetRegisterForm();
  }

  onSuccess = response => {
    //console.log("SUCCESS", response);
    this.googleResponse(response);
  };

  onFailure = response => {
    //console.log("FAILED", response);
  };

  onLogoutSuccess = () => {
    //console.log("SUCESS LOG OUT");
  };

  appleLogin = async () => {
    const resp = await signInApple();
    //console.log(resp);
    this.setState({ isLoading: true });
    this.signIn(resp, "apple");
  };

  googleResponse = response => {
    //console.log(response);
    this.setState({ isLoading: true });
    this.signIn(response, "google");
  };

  signIn = (res, type) => {
    const { socialLogin } = this.props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "apple" && res) {
      socialLogin(res, this.props.history, type);
    }
  };

  register = responce => {
    //console.log(responce);
    registerWithEmailPassword(
      responce.username,
      responce.email,
      responce.password
    );
  };

  render() {
    console.log(this.props);
    if (this.props.registrationError && this.props.registrationError != "") {
      this.setState({ isLoading: false });
    }
    return (
      <React.Fragment>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div> */}
        {this.state.isLoading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Register</h5>
                            {/* <p>Get your free Skote account now.</p> */}
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profileImg} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div>
                        <Link to="/register">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title  bg-light">
                              <img
                                src={logoImg}
                                alt=""
                                className=""
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        {this.props.registrationError &&
                        this.props.registrationError ? (
                          <Alert color="danger">
                            {this.props.registrationError}
                          </Alert>
                        ) : null}
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            email: (this.state && this.state.email) || "",
                            password: (this.state && this.state.password) || "",
                            confirmpassword:
                              (this.state && this.state.confirmpassword) || "",
                            firstname:
                              (this.state && this.state.firstname) || "",
                            lastname: (this.state && this.state.lastname) || "",
                            phone: (this.state && this.state.phone) || "",
                          }}
                          validationSchema={
                            this.state.showpass == false
                              ? Yup.object().shape({
                                  firstname: Yup.string().required(
                                    "Please Enter Valid First Name"
                                  ),
                                  lastname: Yup.string().required(
                                    "Please Enter Valid Last Name"
                                  ),
                                  email: Yup.string()
                                    .required("Please Enter Your Email")
                                    .email("Enter Valid Email"),

                                  phone: Yup.string()
                                    .required("Please Enter Phone Number")
                                    .matches(
                                      this.state.phoneRegExp,
                                      "Phone number is not valid"
                                    ),
                                  // .min(10, "Phone Number is to short")
                                  // .max(10, "Phone Number is to long"),
                                })
                              : Yup.object().shape({
                                  firstname: Yup.string().required(
                                    "Please Enter Valid First Name"
                                  ),
                                  lastname: Yup.string().required(
                                    "Please Enter Valid Last Name"
                                  ),
                                  email: Yup.string()
                                    .required("Please Enter Your Email")
                                    .email("Enter Valid Email"),

                                  password: Yup.string().required(
                                    "Please Enter Valid Password"
                                  ),
                                  phone: Yup.string()
                                    .required("Please Enter Phone Number")
                                    .matches(
                                      this.state.phoneRegExp,
                                      "Phone number is not valid"
                                    ),
                                  // .min(10, "Phone Number is to short")
                                  // .max(10, "Phone Number is to long"),
                                  confirmpassword: Yup.string()
                                    .required("Please Enter Confirm Password.")
                                    .oneOf(
                                      [Yup.ref("password")],
                                      "Your passwords do not match."
                                    ),
                                })
                          }
                          onSubmit={values => {
                            this.setState({ isLoading: true });
                            this.props.registerUser(values, this.props.history);
                            // this.register(values)
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form className="form-horizontal">
                              <div className="mb-3">
                                <Label for="firstname" className="form-label">
                                  First Name
                                </Label>
                                <Field
                                  name="firstname"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.firstname && touched.firstname
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="firstname"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label for="lastname" className="form-label">
                                  Last Name
                                </Label>
                                <Field
                                  name="lastname"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.lastname && touched.lastname
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="lastname"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label for="email" className="form-label">
                                  Email
                                </Label>
                                {this.state.email ? (
                                  <Field
                                    name="email"
                                    type="email"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    readOnly="readOnly"
                                  />
                                ) : (
                                  <Field
                                    name="email"
                                    type="email"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                )}

                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label for="phone" className="form-label">
                                  Phone Number
                                </Label>
                                <Field
                                  name="phone"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.phone && touched.phone
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div
                                className={
                                  this.state.showpass == false
                                    ? "mb-3 hide"
                                    : "mb-3"
                                }
                              >
                                <Label for="password" className="form-label">
                                  Password
                                </Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Field
                                    name="password"
                                    type={
                                      this.state.showPassword
                                        ? "text"
                                        : "password"
                                    }
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <button
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        showPassword: !this.state.showPassword,
                                      });
                                    }}
                                    className="btn btn-light "
                                    type="button"
                                    id="password-addon"
                                  >
                                    <i className="mdi mdi-eye-outline"></i>
                                  </button>
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  this.state.showpass == false
                                    ? "mb-3 hide"
                                    : "mb-3"
                                }
                              >
                                <Label
                                  for="confirmpassword"
                                  className="form-label"
                                >
                                  Confirm Password
                                </Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Field
                                    name="confirmpassword"
                                    type={
                                      this.state.showCPassword
                                        ? "text"
                                        : "password"
                                    }
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.confirmpassword &&
                                      touched.confirmpassword
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <button
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        showCPassword:
                                          !this.state.showCPassword,
                                      });
                                    }}
                                    className="btn btn-light "
                                    type="button"
                                    id="password-addon"
                                  >
                                    <i className="mdi mdi-eye-outline"></i>
                                  </button>
                                  <ErrorMessage
                                    name="confirmpassword"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Register
                                </button>
                              </div>
                              {this.state.showpass == false ? null : (
                                <div className="mt-4 text-center">
                                  <h5 className="font-size-14 mb-3">
                                    Sign up with
                                  </h5>

                                  <ul className="list-inline">
                                    <li className="list-inline-item">
                                      <GoogleLogin
                                        clientId={google.CLIENT_ID}
                                        render={renderProps => (
                                          <Link
                                            to="#"
                                            className="social-list-item bg-danger text-white border-danger"
                                            onClick={renderProps.onClick}
                                          >
                                            <i className="mdi mdi-google" />
                                          </Link>
                                        )}
                                        onSuccess={this.onSuccess}
                                        onFailure={this.onFailure}
                                      />
                                    </li>
                                    <li className="list-inline-item">
                                      <Link
                                        to="#"
                                        className="social-list-item bg-primary text-white border-primary"
                                        onClick={this.appleLogin}
                                      >
                                        <i className="mdi mdi-apple" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              )}

                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  By registering you agree to the our{" "}
                                  <Link to="#" className="text-primary">
                                    Terms of Use
                                  </Link>
                                </p>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      Already have an account ?{" "}
                      <Link to="/login" className="fw-medium text-primary">
                        {" "}
                        Login
                      </Link>{" "}
                    </p>
                    <p>
                      © {new Date().getFullYear()} Loadout. Crafted with
                      <i className="mdi mdi-heart text-danger" /> by{" "}
                      <a href="https://www.intuitionsoftech.com.au/">
                        Intuition SofTech
                      </a>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registerUserSuccessful: PropTypes.string,
  history: PropTypes.object,
  registrationError: PropTypes.any,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  //console.log("state", state);
  const { user, registrationError, loading, successMessage } = state.Account;
  return { user, registrationError, loading, successMessage };
};

export default connect(mapStateToProps, {
  registerUser,
  apiError,
  registerUserFailed,
  registerUserSuccessful,
  socialLogin,
})(Register);
