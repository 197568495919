/* Tour */
export const CREATE_TOUR="CREATE_TOUR";

export const CREATE_TOUR_SUCCESS="CREATE_TOUR_SUCCESS";

export const CREATE_TOUR_ERROR="CREATE_TOUR_ERROR";

export const GET_TOUR_LIST = "GET_TOUR_LIST";

export const GET_TOUR_LIST_SUCCESS = "GET_TOUR_LIST_SUCCESS"

export const GET_TOUR_LIST_ERROR = "GET_TOUR_LIST_ERROR"

export const GET_TOUR_DETAILS = "GET_TOUR_DETAILS";

export const GET_TOUR_DETAILS_SUCCESS = "GET_TOUR_DETAILS_SUCCESS"

export const GET_TOUR_DETAILS_ERROR = "GET_TOUR_DETAILS_ERROR"

export const EDIT_TOUR = "EDIT_TOUR";

export const EDIT_TOUR_SUCCESS = "EDIT_TOUR_SUCCESS"

export const EDIT_TOUR_ERROR = "EDIT_TOUR_ERROR"