const {
  GET_ITEM_LIST_SUCCESS,
  GET_ITEM_LIST_ERROR,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_ERROR,
  ITEM_DETAILS_SUCCESS,
  ITEM_DETAILS_ERROR,
  UPDATE_ITEM,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_ERROR,
  GET_ITEM_LIST,
} = require("./actionTypes");

const INIT_STATE = {
  item: [],
  success: {},
  error: {},
  details: {},
};

const Item = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITEM_LIST:
      return {
        ...state,
        item: [],
      };
    case GET_ITEM_LIST_SUCCESS:
      return {
        ...state,
        item: action.payload,
      };
    case GET_ITEM_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_ITEM_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case CREATE_ITEM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
      };
    case ITEM_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_ITEM:
      return {
        ...state,
        success: action.payload,
      };
    case UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case UPDATE_ITEM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Item;
