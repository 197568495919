import {
  CREATE_ITEM,
  CREATE_ITEM_ERROR,
  CREATE_ITEM_SUCCESS,
  GET_ITEM_LIST,
  GET_ITEM_LIST_ERROR,
  GET_ITEM_LIST_SUCCESS,
  ITEM_DETAILS,
  ITEM_DETAILS_ERROR,
  ITEM_DETAILS_SUCCESS,
  UPDATE_ITEM,
  UPDATE_ITEM_ERROR,
  UPDATE_ITEM_SUCCESS,
} from "./actionTypes";

export const getItemList = () => ({ type: GET_ITEM_LIST });

export const getItemListSuccess = item => ({
  type: GET_ITEM_LIST_SUCCESS,
  payload: item,
});

export const getItemListError = error => ({ type: GET_ITEM_LIST_ERROR });
export const itemDetails = item => ({ type: ITEM_DETAILS, payload: item });

export const itemDetailsSuccess = item => ({
  type: ITEM_DETAILS_SUCCESS,
  payload: item,
});

export const itemDetailsError = error => ({
  type: ITEM_DETAILS_ERROR,
  payload: error,
});

export const createItem = (item, history) => ({
  type: CREATE_ITEM,
  payload: { item, history },
});

export const createItemSuccess = message => ({
  type: CREATE_ITEM_SUCCESS,
  payload: message,
});

export const createItemError = error => ({
  type: CREATE_ITEM_ERROR,
  payload: error,
});

export const updateItem = (item, history) => ({
  type: UPDATE_ITEM,
  payload: { item, history },
});

export const updateItemSuccess = message => ({
  type: UPDATE_ITEM_SUCCESS,
  payload: message,
});

export const updateItemError = error => ({
  type: UPDATE_ITEM_ERROR,
  payload: error,
});
