import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  FormGroup,
  Label,
  CardBody,
  CardText,
  CardTitle,
  CardFooter,
  CardSubtitle,
  Button,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";

import classnames from "classnames";

import axios from "axios";

// import stripe
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import CheckoutForm from "../NewTour/CheckoutForm";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Images
import img1 from "../../../assets/images/product/img-1.png";
import img7 from "../../../assets/images/product/img-7.png";
import {
  createTour,
  createTourSuccess,
  createTourError,
} from "../../../store/tour/actions";
import {
  getStripeProduct,
  createSubscription,
} from "./../../../store/products/actions";

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" },
    ],
  },
];

const promise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const options = {
  // passing the client secret obtained from the server
  clientSecret: "{{CLIENT_SECRET}}",
};

const stripe = window.Stripe(
  "pk_test_51J61QmLUvUWnsm1rDVAYpYjnXxITBQuecPRj0vBNQL5mg5qBQuob2njCJoMqQk3BVZULXsuSRoNhFZbwCpI4X4KV00uPb66Zx3"
);
const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj;
  }
};

class NewTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: "",
      email: "",
      orderSummary: [
        {
          id: 1,
          img: img1,
          productTitle: "Half sleeve T-shirt (64GB)",
          price: 450,
          qty: 1,
        },
        {
          id: 2,
          img: img7,
          productTitle: "Solid Color T-Shirt",
          price: 225,
          qty: 1,
        },
      ],
      selectedPackage: "",
      selectedProduct: "",
      activeTab: "",
      step: "1",
      productList: [],
      selectedGroup: null,
      tourData: [],
      productNum: 0,
      imagePreview: "",
      selectImage: "",
      photo: "",
      loading: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }

  makePayment = () => {
    //console.log(this.state.tourData, "tourdata");
    const orderData = {
      priceId: this.state.selectedProduct.metadata.price_key,
      type: "test",
      name: this.state.name,
      customer_email: this.state.email,
      client_reference_id: "",
      customer: "",
      tour: this.state.tourData,
      productId: this.state.productNum,
      package_name: this.state.selectedProduct.name,
      price: this.state.selectedProduct.metadata.price,
    };
    // this.setState({ isLoading: true });
    this.props.createSubscription(orderData, this.props.history);
  };

  componentDidMount() {
    this.getProducts();
    const userData = getUserName();
    //console.log(userData);
    if (userData) {
      this.setState({
        name: userData.pub.fn + " " + userData.pub.ln,
        email: userData.pvt.eml,
      });
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps){
  //   console.log(nextProps);
  //   if(!nextProps.loading){
  //     this.setState({loading : true})
  //   }
  // }

  selectPackage = (product, index) => {
    this.setState({ selectedPackage: product.id });
    this.setState({ productNum: index + 1 });
    this.setState({ selectedProduct: product });
  };

  getProducts = () => {
    this.props.getStripeProduct();
  };

  toggleTab(tab) {
    if (this.state.step !== tab) {
      this.setState({
        step: tab,
      });
    }
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup });
  };

  onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      this.setState({ imagePreview: undefined });
      return;
    }

    this.setState({ photo: e.target.files[0] });
    ///console.log(e.target.files[0]);
    localStorage.setItem("tour_img", JSON.stringify(e.target.files[0]));
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        resolve(e.target.result);
        this.setState({ image: e.target.result.split(",")[1] });
        var type = e.target.result.split("/")[1];
        this.setState({ type: type.split(";")[0] });
        this.setState({ imagePreview: e.target.result });
      };
      reader.onerror = err => {
        reject(err);
      };
      reader.readAsDataURL(e.target.files[0]);
    });
  };

  render() {
    if (this.props.error != "") {
      this.setState({ isLoading: false });
    }
    document.title = "New Tour | Loadout - Admin Panel";
    const { selectedGroup } = this.state;
    return (
      <>
        <React.Fragment>
          {this.state.isLoading ? (
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              <Formik
                initialValues={{
                  title: (this.state && this.state.title) || "",
                  subName: (this.state && this.state.subName) || "",
                  photo: (this.state && this.state.photo) || "",
                }}
                validationSchema={Yup.object().shape({
                  title: Yup.string().required("Please Enter Tour Title"),
                  subName: Yup.string().required("Please Enter Sub Name"),
                })}
                onSubmit={values => {
                  let obj = {
                    title: values.title,
                    subName: values.subName,
                  };
                  ///console.log(obj);
                  this.setState({ tourData: obj });
                  this.toggleTab("2");
                  // this.props.createTour(values, this.props.history);
                  // this.login(values);
                }}
              >
                {({ errors, status, touched, values }) => (
                  <Form>
                    <div className="page-content">
                      <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                          title="Ecommerce"
                          breadcrumbItem="New Tour"
                        />
                        <div className="checkout-tabs">
                          <TabContent activeTab={this.state.step}>
                            <TabPane tabId="1">
                              <Row>
                                <Col lg="12" sm="12">
                                  <Card>
                                    <CardBody>
                                      <div>
                                        <CardTitle className="h4">
                                          New Tour
                                        </CardTitle>

                                        <p className="card-title-desc">
                                          Fill all information below
                                        </p>
                                        <div className="mb-3">
                                          <Label
                                            htmlFor="title"
                                            md="2"
                                            className="col-form-label"
                                          >
                                            Name
                                          </Label>
                                          <Field
                                            name="title"
                                            type="text"
                                            value={values.title}
                                            className={
                                              "form-control" +
                                              (errors.title && touched.title
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="title"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <Label
                                            htmlFor="sub-name"
                                            md="2"
                                            className="col-form-label"
                                          >
                                            Sub Name
                                          </Label>
                                          <Field
                                            name="subName"
                                            type="text"
                                            className={
                                              "form-control" +
                                              (errors.subName && touched.subName
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="subName"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>

                                        <div className="mb-3">
                                          <Label
                                            htmlFor="photo"
                                            md="2"
                                            className="col-form-label"
                                          >
                                            Upload photo
                                          </Label>
                                          <input
                                            name="photo"
                                            type="file"
                                            className="form-control"
                                            onChange={this.onSelectFile}
                                            // value={this.state.photo}
                                          />
                                          <ErrorMessage
                                            name="photo"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <img
                                            style={{ width: "20%" }}
                                            src={this.state.imagePreview}
                                          />
                                        </div>
                                        <div className="text-start">
                                          <Link
                                            to="/dashboard"
                                            className="btn btn-dark"
                                            // onClick={() => {
                                            //   this.toggleTab("1");
                                            // }}
                                          >
                                            Back
                                          </Link>
                                        </div>
                                        <div className="text-end">
                                          <button className="btn btn-success">
                                            <i className="bx bx-money me-1" />
                                            Select Subscription
                                          </button>
                                          {/* <Link
                                      to="#"
                                      className="btn btn-success"
                                      onClick={() => {
                                        this.toggleTab("2")
                                      }}
                                    >
                                      <i className="bx bx-money me-1" />Select Subscription
                                    </Link> */}
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="2">
                              <Card>
                                <CardBody>
                                  <div>
                                    <CardTitle className="h4">
                                      Select Subscription Package
                                    </CardTitle>
                                    <p className="card-title-desc">
                                      Select Any One
                                    </p>
                                  </div>
                                </CardBody>
                              </Card>
                              <Row>
                                {this.props.product &&
                                  this.props.product.map((product, index) => (
                                    <Col lg="4" sm="4" key={product.id}>
                                      <Card
                                        style={
                                          this.state.selectedPackage != "" &&
                                          this.state.selectedPackage ==
                                            product.id
                                            ? {
                                                border: "3px solid #34c38f",
                                                minHeight: "100%",
                                              }
                                            : { minHeight: "100%" }
                                        }
                                      >
                                        <CardBody className="pt-5 text-center">
                                          <div className="d-flex">
                                            <div className="flex-grow-1">
                                              <h5>{product.name}</h5>
                                              <p className="text-muted">
                                                {product.description}
                                              </p>
                                            </div>
                                            <div className="ms-3">
                                              {/* <i
                                      className={
                                        "bx " + this.props.pricing.icon + " h1 text-primary"
                                      }
                                    /> */}
                                            </div>
                                          </div>
                                          <div className="py-4">
                                            <h2>
                                              <sup>
                                                <small>$</small>
                                              </sup>{" "}
                                              {product.metadata.price}/{" "}
                                              <span className="font-size-13">
                                                Per Month
                                              </span>
                                            </h2>
                                          </div>
                                          <div className="plan-features mt-3">
                                            <p>
                                              <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                                              {product.metadata.packs} Packs
                                              allowed
                                            </p>
                                            <p>
                                              <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                                              {product.metadata.items} Items
                                              allowed per pack
                                            </p>
                                          </div>
                                        </CardBody>
                                        <CardFooter className="bg-transparent border-top text-muted p-3">
                                          <div className="text-center">
                                            <Link
                                              to="#"
                                              className="btn btn-info"
                                              onClick={() => {
                                                this.selectPackage(
                                                  product,
                                                  index
                                                );
                                              }}
                                            >
                                              <i className="mdi mdi-check-circle-outline text-default" />{" "}
                                              Select Package
                                            </Link>
                                            {/* <CheckoutForm pricing={product} name={this.state.name} email={this.state.email} /> */}
                                          </div>
                                        </CardFooter>
                                      </Card>
                                    </Col>
                                  ))}
                              </Row>
                              <Card className="plan-box mt-3">
                                <CardBody className="d-flex justify-content-between">
                                  <div className="text-start">
                                    <Link
                                      to="#"
                                      className="btn btn-dark"
                                      onClick={() => {
                                        this.toggleTab("1");
                                      }}
                                    >
                                      Back
                                    </Link>
                                  </div>
                                  <div className="text-end">
                                    {/* <button
                                className="btn btn-info"
                                onClick={() => {
                                  this.toggleTab("1")
                                }}
                              >
                                Create Tour
                              </button> */}
                                    {this.state.selectedProduct ? (
                                      // <CheckoutForm
                                      //   pricing={this.state.selectedProduct}
                                      //   name={this.state.name}
                                      //   email={this.state.email}
                                      //   tourData={this.state.tourData}
                                      //   productId={this.state.productNum}
                                      //   onSubscribe={event => {
                                      //     event
                                      //       ? this.setState({ isLoading: true })
                                      //       : null;
                                      //   }}
                                      // />
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={() => {
                                          this.makePayment();
                                        }}
                                      >
                                        Subscribe & Create Tour
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </CardBody>
                              </Card>
                            </TabPane>
                          </TabContent>
                        </div>
                      </Container>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
          {/* </Form> */}
        </React.Fragment>
      </>
    );
  }
}

NewTour.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  product: PropTypes.object,
};

const mapStateToProps = state => {
  ///console.log("state data", state);
  const { error, success, details, loading } = state.Tour;
  const { subscribe, product } = state.StripeProduct;
  return { error, success, product, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    createTour,
    createTourError,
    createTourSuccess,
    getStripeProduct,
    createSubscription,
  })(NewTour)
);
