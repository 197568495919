export const CREATE_ITEM = "CREATE_ITEM"
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS"
export const CREATE_ITEM_ERROR = "CREATE_ITEM_ERROR"

export const GET_ITEM_LIST = "GET_ITEM_LIST"
export const GET_ITEM_LIST_SUCCESS = "GET_ITEM_LIST_SUCCESS"
export const GET_ITEM_LIST_ERROR = "GET_ITEM_LIST_ERROR"


export const ITEM_DETAILS = "ITEM_DETAILS"
export const ITEM_DETAILS_SUCCESS = "ITEM_DETAILS_SUCCESS"
export const ITEM_DETAILS_ERROR = "ITEM_DETAILS_ERROR"

export const UPDATE_ITEM = "UPDATE_ITEM"
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS"
export const UPDATE_ITEM_ERROR = "UPDATE_ITEM_ERROR"
