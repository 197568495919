module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:"958377178006-7m4dsedqmiqqq11qbqag5nss0rqbmi7g.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
  apple : {
    CLIENT_ID : ""
  }
}
