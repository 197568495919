import {
  CREATE_PACK,
  CREATE_PACK_ERROR,
  CREATE_PACK_SUCCESS,
  EDIT_PACK,
  EDIT_PACK_ERROR,
  EDIT_PACK_SUCCESS,
  GET_PACK_DETAILS,
  GET_PACK_DETAILS_ERROR,
  GET_PACK_DETAILS_SUCCESS,
  GET_PACK_LIST,
  GET_PACK_LIST_ERROR,
  GET_PACK_LIST_SUCCESS,
  TOUR_PACK_LIST,
  TOUR_PACK_LIST_ERROR,
  TOUR_PACK_LIST_SUCCESS,
} from "./actionTypes";

export const getPackList = () => ({ type: GET_PACK_LIST });

export const getPackListSuccess = pack => ({
  type: GET_PACK_LIST_SUCCESS,
  payload: pack,
});

export const getPackListError = error => ({
  type: GET_PACK_LIST_ERROR,
  payload: error,
});

export const tourPackList = id => ({ type: TOUR_PACK_LIST, payload: id });

export const tourPackListSuccess = pack => ({
  type: TOUR_PACK_LIST_SUCCESS,
  payload: pack,
});

export const tourPackListError = error => ({
  type: TOUR_PACK_LIST_ERROR,
  payload: error,
});

export const getPackDetails = pack => ({
  type: GET_PACK_DETAILS,
  payload: pack,
});

export const getPackDetailsSuccess = pack => ({
  type: GET_PACK_DETAILS_SUCCESS,
  payload: pack,
});

export const getPackDetailsError = err => ({
  type: GET_PACK_DETAILS_ERROR,
  payload: err,
});

export const createPack = (pack, history) => ({
  type: CREATE_PACK,
  payload: { pack, history },
});

export const createPackSuccess = message => ({
  type: CREATE_PACK_SUCCESS,
  payload: message,
});

export const createPackError = error => ({
  type: CREATE_PACK_ERROR,
  payload: error,
});

export const editPack = (pack, history) => ({
  type: EDIT_PACK,
  payload: { pack, history },
});

export const editPackSuccess = message => ({
  type: EDIT_PACK_SUCCESS,
  payload: message,
});

export const editPackError = error => ({
  type: EDIT_PACK_ERROR,
  payload: error,
});
