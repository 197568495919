import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// Get Tours
import { getTours } from "./../../store/tour/actions";
import { createItem } from "./../../store/item/actions";
import { getPackList } from "../../store/pack/actions";
import { tourPackList } from "store/actions";

class NewItem extends Component {
  constructor(props) {
    super(props);
    this.state = { tour: "", isLoading: false, pack: "" };
  }

  componentDidMount() {
    this.props.getTours();
    this.props.getPackList();
  }

  change = event => {
    this.setState({ tour: event.target.value });
    this.props.tourPackList(event.target.value);
  };

  packchange = event => {
    this.setState({ pack: event.target.value });
  };

  render() {
    var packlist = this.props.tourpack;
    var tourlist = this.props.tours;
    document.title = "New Item | Loadout Admin Dashboard";
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Item" breadcrumbItem="New Item" />
              <Row>
                <Col lg="12" sm="12">
                  <Card>
                    <CardBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          tour: (this.state && this.state.tour) || "",
                          pack: (this.state && this.state.pack) || "",
                          name: (this.state && this.state.name) || "",
                          color: (this.state && this.state.color) || "",
                          dW: (this.state && this.state.dW) || "",
                          dX: (this.state && this.state.dX) || "",
                          dY: (this.state && this.state.dY) || "",
                          dZ: (this.state && this.state.dZ) || "",
                          photo: (this.state && this.state.photo) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          pack: Yup.string().required("Please Select pack"),
                          name: Yup.string().required("Please Enter item Name"),
                          color: Yup.string().required(
                            "Please Enter Color Code"
                          ),
                          dW: Yup.string().required("Please Enter value"),
                          dX: Yup.string().required("Please Enter value"),
                          dY: Yup.string().required("Please Enter value"),
                          dZ: Yup.string().required("Please Enter value"),
                        })}
                        onSubmit={values => {
                          this.setState({ isLoading: true });
                          this.props.createItem(values, this.props.history);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form>
                            <div>
                              <p className="card-title-desc">
                                Fill all information below
                              </p>
                              <Row>
                                <Col lg="12" sm="12">
                                  <div className="mb-3">
                                    <Label>Tour</Label>
                                    <select
                                      className={
                                        "form-control" +
                                        (errors.tour && touched.tour
                                          ? " is-invalid"
                                          : "")
                                      }
                                      name="tour"
                                      onChange={this.change}
                                      value={this.state.tour}
                                    >
                                      <option value="">Select Tour</option>
                                      {tourlist.map((x, y) =>
                                        x.det.st ? (
                                          <option key={y} value={x.id}>
                                            {x.det.nm}
                                            {" - "}
                                            {x.det.snm}
                                          </option>
                                        ) : null
                                      )}
                                      ;
                                    </select>
                                    <ErrorMessage
                                      name="tour"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="12" sm="12">
                                  <div className="mb-3">
                                    <Label>Packs</Label>
                                    <select
                                      className={
                                        "form-control" +
                                        (errors.pack && touched.pack
                                          ? " is-invalid"
                                          : "")
                                      }
                                      name="pack"
                                      onChange={this.packchange}
                                      value={this.state.pack}
                                    >
                                      <option value="">Select Pack</option>
                                      {packlist.map((x, y) => (
                                        <option key={y} value={x.id}>
                                          {x.nm}
                                          {" - "}
                                          {x.cat}
                                        </option>
                                      ))}
                                      ;
                                    </select>
                                    <ErrorMessage
                                      name="pack"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="12" sm="12">
                                  <div className="mb-3">
                                    <Label>Name</Label>
                                    <Field
                                      name="name"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.name && touched.name
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="name"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="12" sm="12">
                                  <div className="mb-3">
                                    <Label>Color Code</Label>
                                    <Field
                                      name="color"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.color && touched.color
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="color"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3" sm="3">
                                  <div className="mb-3">
                                    <Label>dW Value</Label>
                                    <Field
                                      name="dW"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.dW && touched.dW
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="dW"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3" sm="3">
                                  <div className="mb-3">
                                    <Label>dX Value</Label>
                                    <Field
                                      name="dX"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.dX && touched.dX
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="dX"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3" sm="3">
                                  <div className="mb-3">
                                    <Label>dY Value</Label>
                                    <Field
                                      name="dY"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.dY && touched.dY
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="dY"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3" sm="3">
                                  <div className="mb-3">
                                    <Label>dZ Value</Label>
                                    <Field
                                      name="dZ"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.dZ && touched.dZ
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="dZ"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="12" sm="12">
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="photo"
                                      md="2"
                                      className="col-form-label"
                                    >
                                      Upload File
                                    </Label>
                                    <Field
                                      name="photo"
                                      type="file"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name="photo"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div className="text-start">
                                {/* <Link to="/dashboard" className="btn btn-dark">
                                  Back
                                </Link> */}
                                <Button
                                  className="btn btn-dark"
                                  onClick={this.props.history.goBack}
                                >
                                  Back
                                </Button>
                              </div>
                              <div className="text-end">
                                <button
                                  className="btn btn-info mt-3"
                                  type="submit"
                                >
                                  Create Item
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

NewItem.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  tours: PropTypes.object,
  createPack: PropTypes.func,
  pack: PropTypes.object,
};

const mapStateToProps = state => {
  const { error, success, tours } = state.Tour;
  const { tourpack, pack } = state.Pack;
  return { error, success, tourpack, tours, pack };
};

export default withRouter(
  connect(mapStateToProps, { getTours, createItem, getPackList, tourPackList })(
    NewItem
  )
);
