import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Button,
  Alert,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { getPackList } from "../../store/pack/actions";

class PackList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
    };
  }

  componentDidMount() {
    this.props.getPackList();
  }

  render() {
    var packList = this.props.pack;
    packList = packList.reverse();
    const columns = [
      {
        dataField: "tourname",
        text: "Tour",
        sort: true,
      },
      {
        dataField: "nm",
        text: "Name",
        sort: true,
      },
      {
        dataField: "cat",
        text: "Category",
        sort: true,
      },
      // {
      //   text: "Status",
      //   sort: false,
      //   formatter: (value, row) => (
      //     <>
      //       {row.st ? (
      //         <span className="text-success">Active</span>
      //       ) : (
      //         <span className="text-danger">In Active</span>
      //       )}
      //     </>
      //   ),
      // },
      {
        text: "Action",
        formatter: (value, row) => (
          <>
            <Link to={"edit-pack/" + row.id} className="btn btn-info">
              <i className="bx bx-pencil" />
            </Link>
          </>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      // totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;

    //console.log("packlist==>", packList);

    document.title = "Pack List | Loadout Dashboard";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Pack" breadcrumbItem="Pack List" />
            <Row>
              <Col xl="12" sm="12">
                <Card className="overflow-hidden">
                  <CardHeader className="text-end">
                    <Link to="/new-pack" className="btn btn-info">
                      <i className="bx bx-plus" /> Add New Pack
                    </Link>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={packList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={packList}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

PackList.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  pack: PropTypes.object,
};

const mapStateToProps = state => {
  const { error, success, pack } = state.Pack;
  //console.log(state.Pack);
  return { error, success, pack };
};

export default withRouter(connect(mapStateToProps, { getPackList })(PackList));
