import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Button,
  Alert,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { getTourDetails, tourPackList } from "store/actions";
import PackItemList from "pages/items/pack-item-list";

class TourPackList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
    };
  }

  componentDidMount() {
    //console.log(this.props);
    this.props.tourPackList(this.props.id);
    this.props.getTourDetails(this.props.id);
  }

  render() {
    const details = this.props.details;
    var packList = this.props.tourpack;
    packList = packList.reverse();
    const columns = [
      //   {
      //     dataField: "tourname",
      //     text: "Tour",
      //     sort: true,
      //   },
      {
        dataField: "nm",
        text: "Name",
        sort: true,
      },
      {
        dataField: "cat",
        text: "Category",
        sort: true,
      },
      // {
      //   text: "Status",
      //   sort: false,
      //   formatter: (value, row) => (
      //     <>
      //       {row.st ? (
      //         <span className="text-success">Active</span>
      //       ) : (
      //         <span className="text-danger">In Active</span>
      //       )}
      //     </>
      //   ),
      // },
      {
        text: "Action",
        formatter: (value, row) => (
          <>
            <Link
              to={{
                pathname: "/view-pack/" + row.id,
                state: { tour_id: this.props.id },
              }}
              className="btn btn-info btn-sm"
            >
              <i className="fa fa-eye" />
            </Link>
          </>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      // totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;

    const onRowClick = {
      onClick: (e, row, rowIndex) => {
        this.props.history.push("/view-pack/" + row.id);
      },
    };

    // console.log(
    //   "119=============",
    //   this.props.pricing,
    //   this.props.pricing?.metadata.packs,
    //   packList.length
    // );

    document.title = "Pack List | Loadout Dashboard";
    return (
      <React.Fragment>
        {/* <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Pack" breadcrumbItem="Pack List" />
            <Row>
              <Col xl="12" sm="12"> */}
        <Card className="overflow-hidden">
          {/* <CardHeader className="text-end">
                    <Link to="/new-pack" className="btn btn-info">
                      <i className="bx bx-plus" /> Add New Pack
                    </Link>
                  </CardHeader> */}
          <CardBody className="pt-3">
            <Row>
              <Col xl="6">
                <div className="mb-4 h4 card-title text-start">Pack List</div>
              </Col>
              <Col xl="6">
                {details && details.det && details.det.st ? (
                  <div className="mb-4 text-end">
                    {this.props.pricing &&
                    this.props.pricing.metadata.packs > packList.length ? (
                      <Link
                        to={{
                          pathname: "/new-pack",
                          state: { tour_id: this.props.id },
                        }}
                        className="btn btn-sm btn-info"
                      >
                        <i className="bx bx-plus" /> Add New Pack
                      </Link>
                    ) : packList.length >= 0 &&
                      !this.props.pricing?.metadata.packs > packList.length ? (
                      <Link
                        to={{
                          pathname: "/new-pack",
                          state: { tour_id: this.props.id },
                        }}
                        className="btn btn-sm btn-info"
                      >
                        <i className="bx bx-plus" /> Add New Pack
                      </Link>
                    ) : null}
                  </div>
                ) : null}
              </Col>
            </Row>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={columns}
              data={packList}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={packList}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              rowEvents={onRowClick}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
        {/* </Col>
            </Row>
          </Container>
        </div> */}
      </React.Fragment>
    );
  }
}

TourPackList.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  pack: PropTypes.object,
};

const mapStateToProps = state => {
  const { error, success, tourpack } = state.Pack;
  const { details } = state.Tour;
  return { error, success, tourpack, details };
};

export default withRouter(
  connect(mapStateToProps, { tourPackList, getTourDetails })(TourPackList)
);
