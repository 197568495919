import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import { getTours } from "store/actions";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj;
  }
};

class WelcomeComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "admin@mailinator.com",
      profile: "",
      name: "Admin",
    };
  }

  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      this.setState({
        name: userData.pub.fn + " " + userData.pub.ln,
        profile: userData.pub.pURL,
        email: userData.pvt.eml,
      });
    }
    this.props.getTours();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({
          name: userData.pub.fn + " " + userData.pub.ln,
          profile: userData.pub.pURL,
          email: userData.pvt.eml,
        });
      }
    }
  }

  render() {
    var tourslist = this.props.tours ? this.props.tours : [];
    var activeTour = [];
    for (let tour of tourslist) {
      if (tour.det.st) {
        activeTour.push(tour);
      }
    }
    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="10">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p>Loadout Dashboard</p>
                </div>
              </Col>
              <Col xs="2" className="align-self-end">
                <img src={profileImg} alt="" style={{ maxWidth: "70%" }} />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="4">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={
                      this.state.profile != "" ? this.state.profile : profileImg
                    }
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div>
                <h5 className="font-size-15 text-truncate">
                  {this.state.name}
                </h5>
                <p className="text-muted mb-0 text-truncate">
                  {this.state.email}
                </p>
              </Col>

              <Col sm="8">
                <div className="pt-4">
                  <Row>
                    <Col xs="4">
                      <h5 className="font-size-15">{activeTour.length}</h5>
                      <p className="text-muted mb-0">Active Tours</p>
                    </Col>
                    <Col xs="4">
                      <h5 className="font-size-15">{tourslist.length}</h5>
                      <p className="text-muted mb-0">Total Tours</p>
                    </Col>
                    {/* <Col xs="3">
                      <h5 className="font-size-15">$0</h5>
                      <p className="text-muted mb-0">Subscription Package</p>
                    </Col> */}
                    <Col xs="4">
                      <div className="mt-4 text-end">
                        <Link to="/profile" className="btn btn-primary btn-sm">
                          View Profile{" "}
                          <i className="mdi mdi-arrow-right ms-1" />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { error, success, tours } = state.Tour;
  return { error, success, tours };
};

// export default WelcomeComp;
export default withRouter(connect(mapStateToProps, { getTours })(WelcomeComp));
