import { call, put, takeEvery } from "redux-saga/effects";
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import {
  createTourError,
  createTourSuccess,
  editTourError,
  editTourSuccess,
  getToursDetailsError,
  getToursDetailsSuccess,
  getToursError,
  getToursSuccess,
} from "./actions";
import {
  CREATE_TOUR,
  EDIT_TOUR,
  GET_TOUR_DETAILS,
  GET_TOUR_LIST,
} from "./actionTypes";

const fireBaseBackend = getFirebaseBackend();

function* createNewTour({ payload: { tour, history } }) {
  try {
    var resp = yield call(
      fireBaseBackend.newTour,
      tour.tour,
      tour.subscribe,
      tour.productId
    );
    if (resp) {
      //console.log(resp);
      yield put(createTourSuccess("New Tour Created Successfully!!"));
      history.push("/view-tour/" + resp);
    } else {
      yield put(createTourError("Something went wrong!!"));
      history.push("/new-tour");
    }
  } catch (error) {
    //console.log(error);
    yield put(createTourError(error));
  }
}

function* editTour({ payload: { tour, history } }) {
  try {
    // console.log(tour);
    const responce = yield call(fireBaseBackend.editTour, tour, tour.productid);
    // console.log(responce);
    if (responce) {
      yield put(editTourSuccess("Tour Updated Successfully!!"));
      history.push("/view-tour/" + tour.id);
    } else {
      yield put(editTourError("Something went wrong!!"));
      history.push("/view-tour/" + tour.id);
    }
  } catch (error) {
    // console.log(error);
    yield put(editTourError(error));
    history.push("/view-tour/" + tour.id);
  }
}

function* getTourList() {
  try {
    const responce = yield call(fireBaseBackend.getTours);
    // console.log(responce, "tours list");
    if (responce) {
      yield put(getToursSuccess(responce));
    }
  } catch (error) {
    // console.log(error);
    yield put(getToursError(error));
  }
}

function* getTourDetails({ payload: data }) {
  try {
    const responce = yield call(fireBaseBackend.getTourDetails, data);
    // console.log(responce);
    if (responce) {
      yield put(getToursDetailsSuccess(responce));
    } else {
      yield put(getToursDetailsError("something went wrong"));
    }
  } catch (error) {}
}

function* tourSaga() {
  yield takeEvery(CREATE_TOUR, createNewTour);
  yield takeEvery(GET_TOUR_LIST, getTourList);
  yield takeEvery(GET_TOUR_DETAILS, getTourDetails);
  yield takeEvery(EDIT_TOUR, editTour);
}

export default tourSaga;
