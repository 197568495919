import React, { Component } from "react";
import { CardHeader, Container, Table } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Col, Row, Card, CardBody, Button, Alert } from "reactstrap";
//Import Breadcrumb
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getTours,
  getToursSuccess,
  getToursError,
} from "../../store/tour/actions";
import { getItemList } from "../../store/item/actions";

import { getPackList } from "store/actions";

class UserItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
    };
  }

  componentDidMount() {
    this.props.getPackList();
    this.props.getItemList();
  }

  render() {
    var itemlist = this.props.item;
    itemlist = itemlist.reverse();
    var itemlist = this.props.item;
    const columns = [
      {
        dataField: "nm",
        text: "Name",
        sort: true,
      },
      {
        dataField: "col",
        text: "Color Code",
        sort: true,
      },
      //   {
      //     dataField: "dW",
      //     text: "dW Value",
      //     sort: true,
      //   },
      //   {
      //     dataField: "dX",
      //     text: "dX Value",
      //     sort: true,
      //   },
      //   {
      //     dataField: "dY",
      //     text: "dY Value",
      //     sort: true,
      //   },
      //   {
      //     dataField: "dZ",
      //     text: "dZ Value",
      //     sort: true,
      //   },
      // {
      //   text: "Status",
      //   sort: false,
      //   formatter: (value, row) => (
      //     <>
      //       {row.st ? (
      //         <span className="text-success">Active</span>
      //       ) : (
      //         <span className="text-danger">In Active</span>
      //       )}
      //     </>
      //   ),
      // },
      {
        text: "Action",
        formatter: (value, row) => (
          <>
            <Link to={"/edit-item/" + row.id} className="btn btn-info btn-sm">
              <i className="bx bx-pencil" />
            </Link>
          </>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      // totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;

    const onRowClick = {
      onClick: (e, row, rowIndex) => {
        this.props.history.push("/edit-item/" + row.id);
      },
    };

    document.title = "Item List | Loadout Dashboard";
    return (
      <React.Fragment>
        {/* <div className="page-content">
          <Container fluid> */}
        {/* Render Breadcrumbs */}
        {/* <Breadcrumbs title="Item" breadcrumbItem="Item List" /> */}
        <Row>
          <Col xl="12" sm="12">
            <Card className="overflow-hidden">
              {/* <CardHeader className="text-end">
                    <Link to="/new-item" className="btn btn-info">
                      <i className="bx bx-plus btn-sm" /> Add New Item
                    </Link>
                  </CardHeader> */}
              <CardBody className="pt-3">
                <Row>
                  <Col xl="6">
                    <div className="mb-4 h4 card-title text-start">
                      Items List
                    </div>
                  </Col>
                  {this.props.pack && this.props.pack.length > 0 ? (
                    <Col xl="6">
                      <div className="mb-4 text-end">
                        <Link to="/new-item" className="btn btn-info btn-sm">
                          <i className="bx bx-plus" /> Add New Item
                        </Link>
                      </div>
                    </Col>
                  ) : null}
                </Row>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={itemlist}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={itemlist}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  rowEvents={onRowClick}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* </Container>
        </div> */}
      </React.Fragment>
    );
  }
}

UserItems.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
  tours: PropTypes.object,
};

const mapStateToProps = state => {
  const { error, success, tours } = state.Tour;
  const { pack } = state.Pack;
  const { item } = state.Item;
  return { error, success, tours, item, pack };
};

export default withRouter(
  connect(mapStateToProps, {
    getTours,
    getItemList,
    getToursSuccess,
    getToursError,
    getPackList,
  })(UserItems)
);
